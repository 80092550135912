<template>
        <div class="image">
          <label>Attach an image (optional)</label>
          <div class="image-upload">
            <input type="file" @change="updateImage" accept="image/*">
          </div>
          <div class="image-preview" v-if="image_data.length > 0">
            <img class="preview" :src="image_data">
          </div>
        </div>
</template>

<script>
  export default {
        props: ['value'],
        data() {
          return {
            image_data: ''
          }
        },
  
        mounted() {
          if(!!this.value) {
            this.image_data = this.value;
          }
        },
        
        methods: {
          updateImage(event) {
            // Reference to the DOM input element
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if (input.files && input.files[0]) {
              // create a new FileReader to read this image and convert to base64 format
              var reader = new FileReader();
              // Define a callback function to run, when FileReader finishes its job
              reader.onload = async (e) => {
                // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                // Read image as base64 and set to imageData
                const resized_image = await this.resizeImage(e.target.result, 200, 200);
                this.image_data = resized_image;
                this.$emit('input', resized_image);
              }
              // Start the reader job - read file as a data url (base64 format)
              reader.readAsDataURL(input.files[0]);
            }
          },
          resizeImage(image_uri, width, height) {
            return new Promise((res,rej) => {
              //Draw image expects image to be an image element
              var img = document.createElement('img');
  
              img.onload = () => {
                // create an off-screen canvas
                var canvas = document.createElement('canvas'),
                    ctx = canvas.getContext('2d');
  
                // set its dimension to target size
                canvas.width  = width;
                canvas.height = height;
            
                // draw source image into the off-screen canvas:
                this.drawImageCropToFit(ctx, img);
            
                // encode image to data-uri with base64 version of compressed image
                const resized_uri = canvas.toDataURL('image/jpeg', 0.8);
                res(resized_uri);
              };
              img.src = image_uri;
            })
          },
          drawImageCropToFit(ctx, img, x, y, w, h, offsetX, offsetY) {
            if (arguments.length === 2) {
              x = y = 0;
              w = ctx.canvas.width;
              h = ctx.canvas.height;
            }
        
            // default offset is center
            offsetX = typeof offsetX === "number" ? offsetX : 0.5;
            offsetY = typeof offsetY === "number" ? offsetY : 0.5;
        
            // keep bounds [0.0, 1.0]
            if (offsetX < 0) offsetX = 0;
            if (offsetY < 0) offsetY = 0;
            if (offsetX > 1) offsetX = 1;
            if (offsetY > 1) offsetY = 1;
        
            var iw = img.width,
                ih = img.height,
                r = Math.min(w / iw, h / ih),
                nw = iw * r,   // new prop. width
                nh = ih * r,   // new prop. height
                cx, cy, cw, ch, ar = 1;
        
            // decide which gap to fill    
            if (nw < w) ar = w / nw;                             
            if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
            nw *= ar;
            nh *= ar;
        
            // calc source rectangle
            cw = iw / (nw / w);
            ch = ih / (nh / h);
        
            cx = (iw - cw) * offsetX;
            cy = (ih - ch) * offsetY;
        
            // make sure source rectangle is valid
            if (cx < 0) cx = 0;
            if (cy < 0) cy = 0;
            if (cw > iw) cw = iw;
            if (ch > ih) ch = ih;
        
            // fill image in dest. rectangle
            ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
        }
        },
      }
</script>
