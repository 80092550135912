<template>
  <div class="layout app" :class="classes">
    <HeaderDesktop />
    <HeaderMobile />

    <div class="app-inner">
      <section class="container" :data-template="templateClass">
        <slot></slot>
        <section class="element element-sticky">
          <div class="sticky-inner">
            <nav class="menu">
              <ul>
                <ShopifyLinks :links="linklists['sticky']?.links" />
              </ul>
            </nav>
          </div>
        </section>
      </section>

      <FooterDesktop />

      <OverlayMenu />
      <OverlayCart />
      <OverlayNewsletter />
      <OverlayMobile />
      <!-- <OverlayWishlistShare v-if="isTemplate('wishlist')" /> -->
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  LiquidCart,
  LiquidLinkList,
  LiquidProduct,
  LiquidSettings,
  LiquidShop,
  LiquidTemplate,
} from '../../types/liquid'
import { useAppContext } from '../../context/useAppContext'

import ShopifyLinks from '../shopify/ShopifyLinks.vue'

import HeaderDesktop from '../header/HeaderDesktop.vue'
import HeaderMobile from '../header/HeaderMobile.vue'
import FooterDesktop from '../footer/FooterDesktop.vue'

import OverlayCart from '../overlays/OverlayCart.vue'
import OverlayMobile from '../overlays/OverlayMobile.vue'
import OverlayMenu from '../overlays/OverlayMenu.vue'
import OverlayNewsletter from '../overlays/OverlayNewsletter.vue'
import OverlayWishlistShare from '../overlays/OverlayWishlistShare.vue'

const props = defineProps<{
  currencyCode: string
  giftwrapProduct: LiquidProduct
  shop: LiquidShop
  settings: LiquidSettings
  linklists: LiquidLinkList[]
  cart: LiquidCart
  template: LiquidTemplate
  templateClass: string
  isLoggedIn: boolean
  userId?: string
}>()

const { classes, linklists, templateClass } = useAppContext(props)
</script>
