<template>
  <main>
    <BlogEdit v-if="blog.handle == 'the-edit'" />
    <!-- <BlogEveryday v-if="blog.handle == 'the-everyday'" /> -->
    <BlogJournal v-if="blog.handle == 'journal'" />
  </main>
</template>

<script lang="ts" setup>
import { LiquidBlog, LiquidCollection, LiquidPagination } from '../../types/liquid'
import BlogEdit from './BlogEdit.vue'
import BlogJournal from './BlogJournal.vue'
import { useBlogContext } from '../../context/useBlogContext'

const props = defineProps<{
  blog: LiquidBlog
  makersCollection?: LiquidCollection
  pagination: LiquidPagination
}>()
useBlogContext(props)
</script>
