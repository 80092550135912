<template>
  <section class="blog">
    <div class="blocks blocks-edit">
      <BlogEditCard v-for="article in blog.articles" :article="article" :key="article.handle" />
    </div>

    <BlogPagination />
  </section>
</template>

<script lang="ts" setup>
import BlogEditCard from './BlogEditCard.vue'
import { useBlogInject } from '../../context/useBlogContext'
import BlogPagination from './BlogPagination.vue'

const { blog } = useBlogInject()
</script>
