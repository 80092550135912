import { createApp } from 'vue'
import _ from 'lodash'
import $ from 'jquery'

import LayoutDefault from '../components/layout/LayoutDefault.vue'

import LazyImage from '../components/common/LazyImage.vue'

import HomePage from '../components/home/HomePage.vue'
import CollectionPage from '../components/collection/CollectionPage.vue'
import ProductPage from '../components/product/ProductPage.vue'
import MakersPage from '../components/makers/MakersPage.vue'
import BlogPage from '../components/blog/BlogPage.vue'
import ArticlePage from '../components/article/ArticlePage.vue'
import SearchPage from '../components/search/SearchPage.vue'
import ErrorPage from '../components/error/ErrorPage.vue'
import WishlistPage from '../components/wishlist/WishlistPage.vue'

import PageDefault from '../components/page/PageDefault.vue'
import PageText from '../components/page/PageText.vue'
import PageImage from '../components/page/PageImage.vue'
import PageColumns from '../components/page/PageColumns.vue'
import PageShipping from '../components/page/PageShipping.vue'

import store from '../store'

window._ = _
window.$ = $

// Vue App
window._app = createApp({
  components: {
    LazyImage,
    LayoutDefault,
    HomePage,
    CollectionPage,
    ProductPage,
    MakersPage,
    BlogPage,
    ArticlePage,
    SearchPage,
    ErrorPage,
    WishlistPage,

    PageDefault,
    PageText,
    PageImage,
    PageColumns,
    PageShipping,
  },

  mounted() {
    this.$store.dispatch('screen/initialize')
  },
})

window._app.use(store)
window._app.mount('#app')
