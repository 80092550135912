<template>
    <div class="login">
      Your Edit is available for logged in users,<br>
      Please <a href="/account/login">log in</a> or <a href="/account/register">create an account</a> to get started.
    </div>
</template>

<script>
  
  export default {
    props: [],
  }
</script>
