import { provide, inject, Ref, ref, watch, onMounted } from 'vue'
import { getCookie, KLAVIYO_LIST_ID, setCookie } from '../helpers'
import { subscribe } from 'klaviyo-subscribe'

export type NewsletterContextProps = {
  isLoggedIn: boolean
}

export type NewsletterContextType = {
  show: Ref<boolean>
  email: Ref<string>
  message: Ref<string>
  close: any
  submit: any
}

export const NEWSLETTER_INJECTION_KEY = Symbol('NEWSLETTER')

export const useNewsletterContext = ({ isLoggedIn }: NewsletterContextProps) => {
  const show = ref(false)
  const message = ref('')
  const email = ref('')

  onMounted(() => {
    if (isLoggedIn) return

    const hideTime = getCookie('NEWSLETTER_CLOSED')

    if (hideTime) {
      const twoWeeks = 1000 * 60 * 60 * 24 * 14
      const timeAgo = Date.now() - hideTime
      if (timeAgo < twoWeeks) return
    }

    setTimeout(() => (show.value = true), 6000)
  })

  const close = () => {
    const currentTime = Date.now()
    setCookie('NEWSLETTER_CLOSED', currentTime.toString())
    show.value = false
  }

  const submit = async () => {
    try {
      const klaviyoResponse = await subscribe(KLAVIYO_LIST_ID, email.value, {})
      if (klaviyoResponse?.success == false) {
        message.value = (klaviyoResponse?.errors || []).join(', ')
      } else {
        message.value = 'Signed Up!'
      }
    } catch (e) {
      console.log('error', e)
    }
  }

  const values = {
    show,
    close,
    message,
    email,
    submit,
  }

  provide<NewsletterContextType>(NEWSLETTER_INJECTION_KEY, values)
  return values
}

export const useNewsletterInject = () => {
  const context = inject<NewsletterContextType>(NEWSLETTER_INJECTION_KEY)
  if (!context) throw new Error()
  return context
}
