<template>
  <div v-html="settings[name]"></div>
</template>

<script lang="ts" setup>
import { useAppInject } from '../../context/useAppContext.ts'
const props = defineProps<{ name: string }>()

const { settings } = useAppInject()
</script>
