<template>
  <header class="mobile">
    <div class="wrap">
      <div class="inner">
        <nav class="hamburger" @click="setOverlay('MOBILE')">
          <i></i>
          <i></i>
          <i></i>
        </nav>
        <div class="mobile-logo" :class="{ active: showLogo }">
          <a href="/">
            <SvgMobileLogo />
          </a>
        </div>
        <nav class="cart">
          <span class="a" @click.prevent="setOverlay('CART')"
            >Cart (<span>{{ itemCount }}</span
            >)</span
          >
        </nav>
      </div>
    </div>
    <div class="spacer"></div>
  </header>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useAppInject } from '../../context/useAppContext'
import { useCartInject } from '../../context/useCartContext'
import { useOverlayInject } from '../../context/useOverlayContext'
import SvgMobileLogo from '../svg/SvgMobileLogo.vue'

const { isTemplate } = useAppInject()
const { setOverlay } = useOverlayInject()
const { itemCount } = useCartInject()

const showLogo = ref(!isTemplate('index'))
const handleScroll = () => {
  if (isTemplate('index')) {
    showLogo.value = window.scrollY > window.innerHeight - 30
  }
}

onMounted(() => {
  if (isTemplate('index')) {
    window.addEventListener('scroll', handleScroll)
    handleScroll()
  }
})
</script>
