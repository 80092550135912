<template>
  <div class="option" :class="{ active: show }" @mouseleave="show = false">
    <h3>{{ option.name }}</h3>
    <nav class="toggle" @click.prevent="show = !show"></nav>
    <ul>
      <li class="active" @click.prevent="show = !show">
        <span>{{ selectedOption }}</span>
      </li>
      <li v-for="value in option.values" :key="value">
        <span v-if="value != selectedOption" @click="select(value)">{{ value }}</span>
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { LiquidOption } from '../../types/liquid'
import { useProductInject } from '../../context/useProductContext'

const show = ref(false)

const { selectedOptions, updateOptions } = useProductInject()
const props = defineProps<{ option: LiquidOption }>()
const selectedOption = computed(() => selectedOptions.value[props.option.position - 1])

const select = (value: string) => {
  updateOptions(props.option.name, value)
  show.value = false
}
</script>
