<template>
  <section class="details">
    <div class="heading">
      <h1>{{ page.title }}</h1>
    </div>
    <div class="column1">
      <ShopifyContent :content="contents[0]" />
    </div>
    <div v-if="contents[1]" class="column2">
      <ShopifyContent :content="contents[1]" />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { LiquidPage } from '../../typ../types/liquid'
import ShopifyContent from '../shopify/ShopifyContent'

const props = defineProps<{ page: LiquidPage }>()

const contents = props.page.content.split('<!--split-->')
</script>
