<template>
  <div v-if="isTemplate('index')" class="overlay overlay-newsletter" :class="{ active: show }">
    <div class="close" @click="close"><i></i><i></i></div>
    <form @submit.prevent="submit">
      <p>{{ settings.home_newsletter_text }}</p>
      <div class="signup">
        <input type="text" v-model="email" placeholder="Email" required /><br />
        <button></button>
      </div>
    </form>
    <div v-if="message" v-html="message" class="message"></div>
  </div>
</template>

<script lang="ts" setup>
import { useAppInject } from '../../context/useAppContext'
import { useNewsletterInject } from '../../context/useNewsletterContext'

const { settings, isTemplate } = useAppInject()
const { close, message, email, submit, show } = useNewsletterInject()
</script>
