import axios from 'axios'
import stripJsonComments from 'strip-json-comments'
import qs from 'qs'
import _ from 'lodash'

const axios_get = {
  dataType: 'json',
  // contentType: 'application/json',
}

const axios_post = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
}

const AxiosGet = async function (url, overrides = {}) {
  var settings = _.merge({}, axios_get, overrides)
  var response = await axios.get(url, settings)

  if (!!overrides.raw) {
    return response
  } else {
    return typeof response.data == 'string'
      ? JSON.parse(stripJsonComments(response.data))
      : response.data
  }
}

const AxiosPost = async function (url, data, overrides = {}) {
  var settings = _.merge({}, axios_post, overrides)
  var response = await axios.post(url, qs.stringify(data.data), settings)

  return response.data
}

const addField = function (key, value, form) {
  var hiddenField = document.createElement('input')
  hiddenField.setAttribute('type', 'hidden')
  hiddenField.setAttribute('name', key)
  hiddenField.setAttribute('value', value)

  form.appendChild(hiddenField)
}

const submitRequest = function (path, params, _method = false) {
  var method = 'post'

  var form = document.createElement('form')
  form.setAttribute('method', method)
  form.setAttribute('action', path)

  //Add form decoration
  // addField('utf8', "✓", form);
  // addField('form_type', "customer_address", form);
  if (_method) addField('_method', _method, form)

  //Add attributes
  for (var key in params) {
    if (params.hasOwnProperty(key)) {
      var form_key = key
      // if (form_key == 'country_code') form_key = 'country';
      // if (form_key == 'province_code') form_key = 'province';

      if (!!params[key] && params[key] != '') addField(form_key, params[key], form)
    }
  }

  document.body.appendChild(form)
  form.submit()

  return form
}

const AxiosForm = async function (url, data, method = 'post') {
  // var settings = _.merge({
  //   url: url,
  //   method: method,
  //   data: qs.stringify(data),
  // }, axios_form, overrides);

  var response = submitRequest(url, data) //(await axios.request(settings));

  // if (
  //   response.request.responseURL.indexOf('/challenge') > -1
  // ) {
  //   window.location.href = '/challenge';
  // }

  return response.data
}

export { axios_get, axios_post, AxiosGet, AxiosPost, AxiosForm }
