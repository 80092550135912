<template>
  <transition name="fade">
    <div class="overlay overlay-menu" v-if="menuActive">
      <section class="collections">
        <nav>
          <ul>
            <li
              v-for="(_collection, index) in linklists['menu-collections'].links"
              :key="_collection.url"
            >
              <h2>
                <a :href="_collection.url">{{ _collection.title }}</a>
              </h2>
              <ul>
                <li
                  v-for="link in linklists[`collection-${_collection.object.handle}`]?.links"
                  :key="link.url"
                >
                  <a :href="link.url">{{ link.title }}</a>
                  <div
                    v-if="link.image"
                    class="image"
                    :style="
                      menuImageStyle({
                        src: link.image.src,
                        width: link.image.width,
                        height: link.image.height,
                      })
                    "
                  ></div>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </section>

      <section class="footer">
        <nav>
          <ul>
            <ShopifyLinks :links="linklists['menu-sticky'].links" />
          </ul>
        </nav>
      </section>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import ShopifyLinks from '../shopify/ShopifyLinks.vue'
import { useAppInject } from '../../context/useAppContext'
import { useHeaderInject } from '../../context/useHeaderContext'

const { linklists } = useAppInject()
const { menuActive } = useHeaderInject()

const styles = ref([])

interface ImageStyle {
  top: string
  left: string
  width: string
  height: string
  'background-image': string
}

const hashCode = function (str: string): number {
  let ret = 0
  for (let i = 0; i < str.length; i++) {
    ret = (31 * ret + str.charCodeAt(i)) << 0
  }
  return ret
}

const menuImageStyle = (image: any): ImageStyle => {
  if (!image?.src) return {}

  const code = hashCode(image.src)

  if (styles.value[code] !== undefined) {
    return styles.value[code]
  } else {
    const windowHeight = window.innerHeight
    const windowWidth = window.innerWidth

    const imageWidth = (windowWidth / 30) * 6
    const imageHeight = (image.height / image.width) * imageWidth

    const paddingTop = 100
    const paddingLeft = (windowWidth / 30) * 3
    const paddingRight = (windowWidth / 30) * 3

    const imageTop = _.random(paddingTop, windowHeight - imageHeight - 100)
    const imageLeft = _.random(paddingLeft, windowWidth - imageWidth - paddingRight)

    const v: ImageStyle = {
      top: `${imageTop}px`,
      left: `${imageLeft}px`,
      width: `${imageWidth}px`,
      height: `${imageHeight}px`,
      'background-image': `url(${image.src})`,
    }

    styles.value[code] = v
    return v
  }
}
</script>
