<template>
  <div class="empty">
    <h2>There is nothing in Your Edit yet (or nothing is currently in stock).</h2>
    <p>You can save items via the product page.</p>
  </div> 
</template>

<script>
export default {
  props: {},
}
</script>