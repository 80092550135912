<template>
  <div class="buy">
    <!-- product/buy/button -->
    <div class="add">
      <button @click="buy" :disabled="!isVariantAvailable">{{ content.buyText }}</button>
    </div>

    <!-- product/buy/layby -->
    <div class="pay-later" v-if="selectedVariant.available">
      Or
      <a href="https://www.laybuy.com/what-is-laybuy" target="_blank" rel="noopener">
        <SvgLaybuy />
        6 x <ShopifyPrice :amount="selectedVariant.price / 6" />
        &#9432;
      </a>

      <a href="https://www.afterpay.com/how-it-works" target="_blank" rel="noopener">
        <SvgAfterpay />
        4 x <ShopifyPrice :amount="selectedVariant.price / 4" />
        &#9432;
      </a>
    </div>
    <!-- product/buy/message -->
    <div class="message" v-if="content.buyMessage">{{ content.buyMessage }}</div>

    <!-- product/buy/notify -->
    <ProductNotify
      v-if="!isVariantAvailable"
      :product-id="product.id"
      :variant-id="selectedVariant.id"
    />

    <!-- product/buy/wishlist -->
    <WishlistAdd />
  </div>
</template>
<script setup lang="ts">
import { useProductContent } from '../../composables/useProductContent'
import { useCartInject } from '../../context/useCartContext'
import { useOverlayInject } from '../../context/useOverlayContext'
import { useProductInject } from '../../context/useProductContext'
import ShopifyPrice from '../shopify/ShopifyPrice.vue'
import SvgAfterpay from '../svg/SvgAfterpay.vue'
import SvgLaybuy from '../svg/SvgLaybuy.vue'
import ProductNotify from './ProductNotify.vue'
import WishlistAdd from '../wishlist/WishlistAdd.vue'
//
const content = useProductContent()
const { setOverlay } = useOverlayInject()
const { addLineItem } = useCartInject()
const { isVariantAvailable, product, selectedVariant } = useProductInject()

const buy = async () => {
  await addLineItem({ variantId: selectedVariant.value.id, quantity: 1 })
  setOverlay('CART')
}
</script>
