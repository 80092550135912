<template>
  <div class="block">
    <div class="article">
      <a class="link" :href="article.url"></a>
      <div class="heading">
        <div class="date">
          {{ formatDate(article.published) }}
        </div>
        <div class="title">
          <h2>{{ article.title }}</h2>
        </div>
      </div>
      <div :class="`image ${imageClass}`">
        <ShopifyImage v-if="index == 3" :src="article.image" ratio="12x9" :alt="article.title" />
        <ShopifyImage v-else :src="article.image" :width="900" :alt="article.title" />
      </div>
      <div class="details">
        <div class="excerpt">
          <ShopifyContent :content="article.excerpt" />
        </div>
        <span class="read">Read Post</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { formatDate } from '../../helpers'
import { LiquidArticle } from '../../types/liquid'
import ShopifyImage from '../shopify/ShopifyImage.vue'
import ShopifyContent from '../shopify/ShopifyContent'
import { useArticleContext } from '../../context/useArticleContext'

const props = defineProps<{ article: LiquidArticle; index: number }>()
const { imageClass } = useArticleContext(props)
</script>
