<template>
  <footer class="main" ref="footer">
    <div class="inner">
      <nav class="contact">
        <CommonSetting name="footer_contact" />
        <form method="get" @submit.prevent="submit">
          <p>Subscribe to our Newsletter</p>
          <div class="signup">
            <input type="text" v-model="email" placeholder="Email" required />
            <button></button>
          </div>
        </form>
        <div v-if="message !== ''" v-html="message" class="message"></div>
      </nav>

      <nav class="menu">
        <ul>
          <ShopifyLinks :links="linklists['footer']?.links" />
        </ul>
      </nav>

      <div class="address">
        <CommonSetting name="footer_address" />
      </div>

      <div class="credit">
        <a href="http://www.1of1studio.com/" target="_blank">Site by 1/1</a>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { useAppInject } from '../../context/useAppContext'
import ShopifyLinks from '../shopify/ShopifyLinks.vue'
import CommonSetting from '../common/CommonSetting.vue'
import { useNewsletterInject } from '../../context/useNewsletterContext'

const { settings, linklists } = useAppInject()
const { message, email, submit } = useNewsletterInject()
</script>
