import { AxiosGet, AxiosPost } from './axios';

export default class Default {
  async create(identifier, is_guest = false) {
    let name = 'default';
    try {
      return (await AxiosPost('api/v1/wishlists', {data:{name, identifier, is_guest, items: []}})).wishlist;
    } catch(e) {
      console.log('Api Error: ', e);
    }
  }

  async read(identifier) {
    let name = 'default'; 
    try {
      return (await AxiosGet('api/v1/wishlists', {params:{name, identifier}})).wishlist;
    } catch(e) {
      console.log('Api Error: ', e);
    }
  }

  async update(identifier, data = {}) {
    let name = 'default';
    try {
      let response = await AxiosPost('api/v1/wishlists', {method: 'PUT', data: {
        name, 
        identifier,
        data
      }});
      
      return response.wishlist;
    } catch(e) {
      console.log('Api Error: ', e);
    }
  }

  async updateItems(identifier, items = []) {
    let name = 'default';
    try {
      let response = (await AxiosPost('api/v1/wishlists', {method: 'PUT', data:{name, identifier, items}}));
      return response.wishlist;
    } catch(e) {
      console.log('Api Error: ', e);
    }
  }

  async delete(identifier) {
    let name = 'default';
    try {
      return await AxiosPost('api/v1/wishlists', {method: 'DELETE', data:{name, identifier}})
    } catch(e) {
      console.log('Api Error: ', e);
    }
  }
  async share(identifier, data) {
    let name = 'default';
    try {
      return (await AxiosPost('api/v1/wishlists/share', {data:{name, identifier, ...data}}));
    } catch(e) {
      console.log('Api Error: ', e);
    }
  }
}  