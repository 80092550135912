<template>
  <section class="element element-sidebar element-sidebar-breadcrumbs">
    <nav class="inner">
      <ul>
        <li><a href="/collections/all">Shop</a></li>
        <li v-for="breadcrumb in filteredBreadcrumbs" :key="breadcrumb.url">
          <a :href="breadcrumb.url">{{ breadcrumb.title }}</a>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script setup lang="ts">
import { useProductInject } from '../../context/useProductContext'

const { breadcrumbs } = useProductInject()
const filteredBreadcrumbs = breadcrumbs.filter(Boolean) as any
</script>
