import {
  fetchCart as _fetchCart,
  addLineItem as _addLineItem,
  addLineItems as _addLineItems,
  updateLineItem as _updateLineItem,
  removeLineItem as _removeLineItem,
  removeLineItems as _removeLineItems,
  updateCart as _updateCart,
  AddLineItemProps,
  UpdateLineItemProps,
  UpdateCartProps,
  RemoveLineItemProps,
  RemoveLineItemsProps,
} from '../ajax'
import { Ref, provide, inject, ref, computed, onMounted } from 'vue'
import { LiquidCart, LiquidProduct } from '../types/liquid'

type CartStatusType = '' | 'ADDING' | 'ADDED' | 'REMOVING' | 'REMOVED'

export type CartContextType = {
  cart: Ref<LiquidCart>
  cartStatus: Ref<CartStatusType>
  hasItems: Ref<boolean>
  itemCount: Ref<number>

  hasGiftwrap: Ref<boolean>
  toggleGiftwrap: any

  fetchCart: any
  updateCart: (_: UpdateCartProps) => any
  addLineItem: (_: AddLineItemProps) => any
  addLineItems: (_: AddLineItemProps[]) => any
  updateLineItem: (_: UpdateLineItemProps) => any
  removeLineItem: (_: RemoveLineItemProps) => any
  removeLineItems: (_: RemoveLineItemsProps) => any
}

export const CART_INJECTION_KEY = Symbol('CART')

export const useCartContext = (initialCart: LiquidCart, giftwrapProduct: LiquidProduct) => {
  const cart: Ref<LiquidCart> = ref(initialCart)
  const cartStatus: Ref<CartStatusType> = ref('')
  const hasItems: CartContextType['hasItems'] = computed(() => cart.value?.items?.length > 0)

  const hasGiftwrap: CartContextType['hasGiftwrap'] = computed(
    () => !!cart.value?.items?.find((item) => item.product_id == giftwrapProduct.id)
  )
  const itemCount: CartContextType['itemCount'] = computed(
    () => cart.value?.items?.length - (hasGiftwrap.value ? 1 : 0) || 0
  )

  const updateGiftwrap = async () => {
    const variantId = giftwrapProduct.variants[0].id
    const hasGiftwrap = !!cart.value?.items?.find((item) => item.product_id == giftwrapProduct.id)
    const shouldGiftwrap = cart.value.attributes?.should_giftwrap == 'true' && cart.value.item_count > 0

    let shouldChange = false
    if (shouldGiftwrap && !hasGiftwrap) shouldChange = true
    if (!shouldGiftwrap && hasGiftwrap) shouldChange = true

    if (shouldChange) {
      if (shouldGiftwrap) {
        await _addLineItem({
          variantId,
          quantity: 1,
        })
      } else {
        await _removeLineItem({ variantId })
      }
    }
  }

  const beforeCartStatus = (status: CartStatusType) => {
    cartStatus.value = status
  }

  const afterCartStatus = (status: CartStatusType) => {
    cartStatus.value = status
    setTimeout(() => (cartStatus.value = ''), 2000)
  }

  const fetchCart = async () => {
    await updateGiftwrap()
    cart.value = await _fetchCart()
  }

  const addLineItems = async (props: AddLineItemProps[]) => {
    beforeCartStatus('ADDING')
    await _addLineItems(props)
    afterCartStatus('ADDED')
    // await wait(dd200)
    await fetchCart()
  }

  const addLineItem = async (props: AddLineItemProps) => {
    beforeCartStatus('ADDING')
    await _addLineItem(props)
    await fetchCart()
    afterCartStatus('ADDED')
  }

  const updateLineItem = async (props: UpdateLineItemProps) => {
    await _updateLineItem(props)
    await fetchCart()
  }

  const removeLineItem = async (props: RemoveLineItemProps) => {
    beforeCartStatus('REMOVING')
    await _removeLineItem(props)
    await fetchCart()
    afterCartStatus('REMOVED')
  }

  const removeLineItems = async (props: RemoveLineItemsProps) => {
    beforeCartStatus('REMOVING')
    await _removeLineItems(props)
    await fetchCart()
    afterCartStatus('REMOVED')
  }

  const updateCart = async (props: UpdateCartProps) => {
    await _updateCart(props)
    await fetchCart()
  }

  const toggleGiftwrap = async () => {
    const should_giftwrap = cart.value.attributes.should_giftwrap != 'true' ? 'true' : 'false'
    await _updateCart({
      attributes: {
        should_giftwrap,
      },
    })

    await updateGiftwrap()
    cart.value = await _fetchCart()
  }

  // Initialise the cart
  onMounted(() => {
    fetchCart()
  })

  provide<CartContextType>(CART_INJECTION_KEY, {
    cart,
    cartStatus,
    hasItems,
    itemCount,
    fetchCart,
    updateCart,
    addLineItem,
    addLineItems,
    updateLineItem,
    removeLineItem,
    removeLineItems,

    hasGiftwrap,
    toggleGiftwrap,
  })
}

export const useCartInject = () => {
  const context = inject<CartContextType>(CART_INJECTION_KEY)
  if (!context) throw new Error()
  return context
}
