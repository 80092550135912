<template>
  <section class="element element-sidebar" :class="{ active }">
    <div class="inner">
      <div class="heading" @click="toggleSidebar">
        <h1>{{ sidebarMenu.title.replace('Collection (', '').replace(')', '') }}</h1>
        <span></span>
      </div>
      <nav class="menu">
        <ShopifyLinks :links="sidebarMenu.links" />
      </nav>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import ShopifyLinks from '../shopify/ShopifyLinks.vue'
import { LiquidLinkList } from '../../types/liquid'

defineProps<{ sidebarMenu: LiquidLinkList }>()

const active = ref(true)
const toggleSidebar = () => (active.value = !active.value)
</script>
