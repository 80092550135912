import { ProductFieldsFragment } from '../storefront/generated'

type ShopifyImageOptions = {
  modifiers: {
    width: number
    ratio: string
  }
}

export function shopifyImage({
  src,
  width,
  height,
  cropping = '_crop_center',
}: // quality,
{
  src: string
  width: number
  height?: number
  cropping?: '_crop_center'
  // quality?: string;
}) {
  const url = `${src}?`
  const dotIndex = url.lastIndexOf('.')
  const format = width ? `_${Math.ceil(width)}x${height ? Math.ceil(height) : ''}${cropping}` : ''

  return [url.slice(0, dotIndex), `${format}`, url.slice(dotIndex)].join('')
}

export const shopifyImageRatio = (src: string, { modifiers }: ShopifyImageOptions) => {
  const finalWidth = Math.min(modifiers.width * 2, 1600)
  const ratio = ratioAsNumber(modifiers.ratio)
  const height = Math.ceil(finalWidth / ratio)

  return {
    url: shopifyImage({
      src,
      width: finalWidth,
      height,
      // quality,
    }),
  }
}

export const lineItemsSumByType = (productType) => (lineItems) =>
  lineItems.reduce((sum, item) => {
    return item.product_type === productType ? sum + item.quantity : sum
  }, 0)

export const findProductTag = (product: ProductFieldsFragment, tag: string) =>
  product.tags.find((_tag) => _tag == tag)

export const findProductTagStartsWith = (product: ProductFieldsFragment, tag: string) =>
  product.tags.find((_tag) => _tag.startsWith(tag))

export function ratioAsNumber(ratio: string): number {
  const [width, height] = ratio.split('x').map((v) => parseFloat(v))
  return width / height
}
