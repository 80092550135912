<template>
  <div class="wishlist">
    <transition name="fade">
      <!-- list -->
      <div class="list" v-if="!!loaded">
        <WishlistLogin v-if="mode == 'owner' && !isLoggedIn" :mode="mode" />

        <div class="sidebar">
          <WishlistDetails :wishlist="wishlist" :mode="mode" />

          <WishlistEdit :wishlist="wishlist" v-if="mode == 'owner' && isLoggedIn" />

          <WishlistInfo :wishlist="wishlist" v-if="mode == 'guest'" />
        </div>

        <WishlistEmpty v-if="items.length == 0" />

        <WishlistItems :items="items" :wishlist="wishlist" :mode="mode" />
      </div>

      <!-- loading -->
      <div class="loading" v-else>Loading Wishlist</div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
// import WishlistAdd from './Add.vue'
import WishlistDetails from './WishlistDetails.vue'
import WishlistEdit from './WishlistEdit.vue'
import WishlistEmpty from './WishlistEmpty.vue'
import WishlistInfo from './WishlistInfo.vue'
import WishlistItems from './WishlistItems.vue'
import WishlistLogin from './WishlistLogin.vue'
import store from '../../store'
import { computed, onMounted, ref } from 'vue'
import { useAppInject } from '../../context/useAppContext'

const { isLoggedIn, userId } = useAppInject()
const { mode, wishlistJson } = defineProps<{ mode: string; wishlistJson: any }>()

// const myWishlist = ref(false)
const loaded = ref(false)

const wishlist = computed(() => {
  return store.state.wishlists.wishlist
})
const items = computed(() => {
  return store.state.wishlists.items
})
onMounted(async () => {
  if (!wishlistJson) {
    await store.dispatch('wishlists/load', { isLoggedIn, userId })
  } else {
    await store.dispatch('wishlists/set', wishlistJson)
  }

  loaded.value = true
})
</script>
