<template>
  <section class="heading">
    <!-- product/heading/price -->
    <div class="amount">
      <h3 v-if="isVariantOnSale">On Sale</h3>
      <h3 v-else>Price</h3>
      <ShopifyPrice :amount="selectedVariant.price" class="price" />
    </div>

    <!-- product/heading/maker -->
    <h2 class="maker" v-if="makerCollection">
      <a :href="makerCollection.url"> {{ makerCollection.title }} </a>
    </h2>

    <!-- product/heading/title -->
    <h1 class="title">{{ product.title }}</h1>
  </section>
</template>

<script setup lang="ts">
import { useProductInject } from '../../context/useProductContext'
import ShopifyPrice from '../shopify/ShopifyPrice.vue'

const { isVariantOnSale, selectedVariant, makerCollection, product } = useProductInject()
</script>
