<template>
  <div class="block">
    <div class="article">
      <div class="heading">
        <div class="title">
          <h2>
            The Edit:<br />
            {{ article.title }}
          </h2>
          <div class="link" v-if="makerCollection">
            <a :href="makerCollection.url">Shop All</a>
          </div>
        </div>
        <div :class="`image ${imageClass}`">
          <ShopifyImage
            v-if="imageClass == 'landscape'"
            :src="article.image"
            :alt="article.title"
            ratio="8x6"
          />
          <ShopifyImage v-else :src="article.image" ratio="6x8" :alt="article.title" />
        </div>
      </div>
      <div class="details">
        <ShopifyContent :content="article.content" />
      </div>
    </div>
    <div class="products" v-if="makerProducts">
      <div class="blocks blocks-edit-product">
        <div class="block" v-for="product in makerProducts.slice(0, 3)" :key="product.handle">
          <a class="link" :href="product.url"></a>
          <div class="image">
            <ShopifyImage :src="product.featured_image.src" ratio="6x8" :alt="product.title" />
          </div>
          <div class="caption">
            {{ product.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ShopifyImage from '../shopify/ShopifyImage.vue'
import { LiquidArticle } from '../../types/liquid'
import ShopifyContent from '../shopify/ShopifyContent'
import { useArticleContext } from '../../context/useArticleContext'

const props = defineProps<{ article: LiquidArticle }>()
const { imageClass, makerCollection, makerProducts } = useArticleContext(props)
</script>
