import Conn from './api';

// Create a random user token for wishlist
// const uuid = function() {
//   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
//     var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
//     return v.toString(16);
//   });
// }

const Api = class {
 constructor() {
   this.conn = new Conn();
 }

 async createWishlist(identifier) {
   //Create new wishlist with token
   let wishlist = await this.conn.create(identifier, false);
   return wishlist;
 }
 
 async getWishlist(identifier) {
   return await this.conn.read(identifier);
 }
 
 async updateWishlist(identifier, data) {
   return await this.conn.update(identifier, data);
 }
 
 async updateWishlistItems(identifier, items) {
   return await this.conn.updateItems(identifier, items);
 }
 
 async deleteWishlist(identifier) {
   return await this.conn.delete(identifier);
 }
 
 async shareWishlist(identifier, data) {
   return await this.conn.share(identifier, data);
 }
}

export default new Api();