export interface DisplayPriceType {
  showDefaultCurrencyCode?: boolean
  hideDoubleZeros?: boolean
  hideAllCurrency?: boolean
  showZeroCents?: boolean
  amount: number
  symbol: string
  code: string
  rate: number
}

export const displayPriceDefaults = {
  showDefaultCurrencyCode: true,
  hideDoubleZeros: true,
  hideAllCurrency: false,
  showZeroCents: false,
  code: 'USD',
  symbol: '$',
  amount: 0,
  rate: 1,
}

export const formatNumber = ({
  amount,
  code,
  showZeroCents,
}: {
  amount: number
  code: string
  showZeroCents: boolean
}) => {
  const floatDigits = showZeroCents ? 2 : 0
  const hasHundredths = amount % 1 != 0
  const hundredths = amount % 1

  const number = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: code,
    currencyDisplay: 'code',
    minimumFractionDigits: floatDigits,
  })
    .format(amount)
    .replace(/[A-Z]+/g, '')
    .replace(code, '')
    .trim()

  return {
    number,
    hundredths,
    hasHundredths,
  }
}
