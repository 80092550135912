import { Module } from 'vuex'
import { RootState } from './index'

export type ArticleState = {
  bodyContentText: string
  bodyContentPullquote: string
  blocks: any[]
}

const articleModule: Module<ArticleState, RootState> = {
  namespaced: true,
  state: (): ArticleState => ({
    bodyContentText: '',
    bodyContentPullquote: '',
    blocks: [],
  }),
  mutations: {
    setBodyContentText(state: ArticleState, text: string) {
      state.bodyContentText = text
    },
    setBodyContentPullquote(state: ArticleState, pullquote: string) {
      state.bodyContentPullquote = pullquote
    },
    setBlocks(state: ArticleState, blocks: any[]) {
      state.blocks = blocks
    },
  },
  actions: {
    formatContent({ commit, getters }) {
      const content = window._data.article.content
      const imgIndex = content.indexOf('<img')
      const splitContent =
        imgIndex >= 0
          ? `${content.substring(0, imgIndex)}<split>${content.substring(imgIndex)}`.split('<split>')
          : [content]

      commit('setBodyContentText', splitContent[0])

      if (splitContent[1]) {
        const blocks = getters.formatBlocks(splitContent[1])
        commit('setBlocks', blocks)
      }
    },
    scrollDown() {
      const top = window.innerHeight
      $('body').velocity('scroll', { duration: 750, offset: top })
    },
  },
  getters: {
    formatBlocks: (state, getters) => (imageContent: string) => {
      const els = getters.htmlToElements(imageContent).querySelectorAll('img, blockquote, iframe')

      if (!els.length) return

      return Array.from(els).map((el) => {
        if (el.nodeName === 'BLOCKQUOTE') return getters.dissectQuote(el as HTMLQuoteElement)
        if (el.nodeName === 'IMG') return getters.dissectImg(el as HTMLImageElement)
        return { html: el.outerHTML }
      })
    },
    dissectImg: (state, getters) => (imgNode: HTMLImageElement) => {
      return {
        image: true,
        src: imgNode.src,
        alt: getters.splitText(imgNode.alt),
        class: 'image ' + getters.splitStyle(imgNode.alt),
      }
    },
    dissectQuote: (state, getters) => (quoteNode: HTMLQuoteElement) => {
      const quoteStr = quoteNode.textContent || ''
      return {
        pullquote: true,
        text: getters.splitText(quoteStr),
        class: 'quote ' + getters.splitStyle(quoteStr),
      }
    },
    splitText: (state, getters) => (str: string) => {
      return getters.splitFormat(str)[0]
    },
    splitStyle: (state, getters) => (str: string) => {
      return getters.splitFormat(str)[1] || ''
    },
    splitFormat: () => (str: string) => {
      return str.split('<format>').map((text) => text.trim())
    },
    htmlToElements: () => (html: string) => {
      const template = document.createElement('template')
      template.innerHTML = html
      return template.content
    },
  },
}

export default articleModule
