<template>
  <div class="details">
    <!-- product/details/description -->
    <div class="description" :class="{ active: tabIndex == 0 }">
      <h3 @click="() => (tabIndex = 0)">Description</h3>
      <transition name="fade">
        <div class="inner" v-if="tabIndex == 0">
          <div v-html="content.description.value"></div>
          <div class="maker" v-if="makerCollection"><a href="#maker">Maker Profile</a></div>
        </div>
      </transition>
    </div>
    <!-- product/details/dimensions -->
    <div class="dimensions" v-if="content.dimensions" :class="{ active: tabIndex == 1 }">
      <h3 @click="tabIndex = 1">Dimensions</h3>
      <transition name="fade">
        <div class="inner" v-if="tabIndex == 1" v-html="content.dimensions.value"></div>
      </transition>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { useProductContent } from '../../composables/useProductContent'
import { useProductInject } from '../../context/useProductContext'

const { makerCollection } = useProductInject()
const content = useProductContent()

const tabIndex = ref(0)
</script>
