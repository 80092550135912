<template>
  <section class="element element-pagination" v-if="pagination.pages > 1">
    <div class="inner">
      <div class="control control-prev" v-if="pagination.previous.url">
        <a :href="pagination.previous.url">Prev</a>
      </div>
      <div class="control control-pages">
        <ul>
          <li
            v-for="part in pagination.parts"
            :key="part.title"
            :class="{ active: parseInt(part.title) == pagination.current_page }"
          >
            <a v-if="part.is_link" :href="part.url" title="">{{ part.title }}</a>
            <span v-else-if="parseInt(part.title) == pagination.current_page">{{ part.title }}</span>
          </li>
        </ul>
      </div>
      <div class="control control-next" v-if="pagination.next.url">
        <a :href="pagination.next.url">Next</a>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useCollectionInject } from '../../context/useCollectionContext'
const { pagination } = useCollectionInject()
</script>
