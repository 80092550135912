import _ from 'lodash'
import { Module } from 'vuex'
import { RootState } from './index'

export type Maker = {
  letter: string
  name: string
  url: string
  src: string
  loaded: boolean
  width: number
  height: number
}

export interface MakersState {
  makers: { [key: string]: Maker[] }
  menuStyles: { [key: string]: any }
}

const makers: Module<MakersState, RootState> = {
  namespaced: true,
  state: (): MakersState => ({
    makers: {},
    menuStyles: {},
  }),
  mutations: {
    setMakers(state, makers: { [key: string]: Maker[] }) {
      state.makers = makers
    },
    setMenuStyles(state, styles: { [key: string]: any }) {
      state.menuStyles = styles
    },
  },
  actions: {
    formatMakers({ commit }) {
      const formattedMakers = _.chain(window._data.makers)
        .map((v: any) => ({
          letter: v.title[0].toUpperCase(),
          name: v.title,
          url: v.url,
          src: v.image,
          loaded: false,
          width: 0,
          height: 0,
        }))
        .groupBy('letter')
        .value()

      commit('setMakers', formattedMakers)

      _.each(formattedMakers, (makers) => {
        _.each(makers, (v) => {
          if (v.loaded) return
          window._utils.helpers.getImageMeta({
            src: v.src,
            onLoad: (d) => {
              v.width = d.width
              v.height = d.height
              v.loaded = true
            },
          })
        })
      })
    },
  },
  getters: {
    showImageStyle: (state) => (makerId: string) => {
      const maker = state.makers[makerId]
      if (!maker || !maker.loaded) return {}

      const hashCode = function (str: string): number {
        let ret = 0
        for (let i = 0; i < str.length; i++) {
          ret = (31 * ret + str.charCodeAt(i)) << 0
        }
        return ret
      }

      const code = hashCode(maker.src)
      const styles = state.menuStyles

      if (styles[code] !== undefined) {
        return styles[code]
      } else {
        const windowHeight = window.innerHeight
        const windowWidth = window.innerWidth

        const imageWidth = (windowWidth / 30) * 6
        const imageHeight = (maker.height / maker.width) * imageWidth

        const paddingTop = 100
        const paddingLeft = (windowWidth / 30) * 3
        const paddingRight = (windowWidth / 30) * 3

        const imageTop = _.random(paddingTop, windowHeight - imageHeight - 100)
        const imageLeft = _.random(paddingLeft, windowWidth - imageWidth - paddingRight)

        const v = {
          top: `${imageTop}px`,
          left: `${imageLeft}px`,
          width: `${imageWidth}px`,
          height: `${imageHeight}px`,
          'background-image': `url(${maker.src})`,
        }

        styles[code] = v
        return v
      }
    },
  },
}

export default makers
