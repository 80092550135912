<template>
  <div class="overlay overlay-cart" :class="{ active: current == 'CART' }">
    <div class="container">
      <div class="inner">
        <div class="close" @click="setOverlay('')"></div>
        <div class="title">
          <h1>
            Your Cart (<span>{{ itemCount }}</span
            >)
          </h1>
        </div>
        <div v-if="hasItems" class="items">
          <div class="item" v-for="item in filteredItems" :key="item.id">
            <div class="image">
              <a :href="item.url">
                <ShopifyImage :src="item.image" ratio="3x4" :alt="item.title" />
              </a>
            </div>
            <div class="description">
              <div class="vendor">{{ item.vendor }}</div>
              <div class="title">
                <span>{{ item.title }}</span>
                <span class="wishlist" v-if="!!item.properties && item.properties.wishlist_id"
                  >(Wishlist)</span
                >
              </div>

              <ShopifyPrice :show-currency-code="true" class="price" :amount="item.price" />
              <div class="quantity">
                Qty:
                <span
                  class="decrease"
                  @click="
                    () => updateLineItem({ variantId: item.variant_id, quantity: item.quantity - 1 })
                  "
                  >−</span
                >
                <span class="value">{{ item.quantity }}</span>
                <span
                  class="increase"
                  @click="
                    () => updateLineItem({ variantId: item.variant_id, quantity: item.quantity + 1 })
                  "
                  >+</span
                >
              </div>
              <div class="remove" @click="() => removeLineItem({ variantId: item.variant_id })">
                Remove
              </div>
            </div>
          </div>
        </div>
        <div v-else class="empty">There are no items in your cart yet!</div>
        <div class="controls">
          <div class="totals">
            <div class="note" v-if="settings.cart_caption">
              <ShopifyContent :content="settings.cart_caption" />
            </div>
            <div class="total">
              <p class="key">Total</p>
              <p class="value">
                <ShopifyPrice :show-currency-code="true" :amount="cart.total_price || 0" />
              </p>
            </div>
            <div class="giftwrap">
              <label
                ><input type="checkbox" :checked="hasGiftwrap" @click="toggleGiftwrap" /> Add Gift
                Wrapping</label
              >
            </div>
            <div class="cart-note">
              <button
                v-if="!cart.note && !showNote"
                @click.prevent="cart.note ? (showNote = true) : (showNote = !showNote)"
              >
                Add note
              </button>
              <div class="input" v-if="showNote || cart.note">
                <textarea v-model="note" placeholder="Order Notes"></textarea>
                <button @click="submitNote">{{ noteButton }}</button>
              </div>
            </div>
          </div>
          <div class="next">
            <a v-if="hasItems" href="/checkout" class="button">Proceed to Checkout</a>
            <a v-else href="/collections/all" class="button">Keep Shopping</a>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="close-panel" v-if="current == 'CART'" @click="setOverlay('')"></div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useAppInject } from '../../context/useAppContext'
import { useCartInject } from '../../context/useCartContext'
import { useOverlayInject } from '../../context/useOverlayContext'
import ShopifyImage from '../shopify/ShopifyImage.vue'
import ShopifyPrice from '../shopify/ShopifyPrice.vue'
import ShopifyContent from '../shopify/ShopifyContent'

const { settings } = useAppInject()
const { current, setOverlay } = useOverlayInject()
const {
  itemCount,
  hasItems,
  cart,
  updateLineItem,
  removeLineItem,
  hasGiftwrap,
  toggleGiftwrap,
  updateCart,
} = useCartInject()

const showNote = ref(false)
const note = ref('')
const noteButton = ref('Save')

onMounted(() => {
  note.value = cart.value.note
})

const filteredItems = computed(() =>
  cart.value.items.filter((i) => i.handle !== 'everydayneedsgiftwrapping')
)

const submitNote = async () => {
  noteButton.value = 'Saving...'
  await updateCart({ note: note.value })
  noteButton.value = 'Saved!'
  setTimeout(() => {
    noteButton.value = 'Save'
  }, 2000)
}
</script>
