import { ref, computed, provide, inject, Ref, unref } from 'vue'
import _ from 'lodash'
import { useProductInject } from '../context/useProductContext.js'
import { useAppInject } from '../context/useAppContext.js'

export type ProductContentType = {
  title: Ref<string>
  description: Ref<string>
  dimensions: Ref<string>
  shipping: string
  buyText: Ref<string>
  buyMessage: Ref<string>
}

export const useProductContent = (): ProductContentType => {
  const { settings } = useAppInject()
  const { product, selectedVariant } = useProductInject()

  const title = computed(() => {
    let ret = product.title
    if (selectedVariant && selectedVariant.value.title !== 'Default Title') {
      ret += ` - ${selectedVariant.value.title}`
    }
    return ret
  })

  const shipping = settings.product_shipping

  const description = computed(() => {
    const html = product.description
    const arr = html.split('<!-- split -->')
    return arr.length > 0 ? arr[0] : html
  })

  const dimensions = computed(() => {
    const html = product.description
    const arr = html.split('<!-- split -->')
    return arr.length > 1 ? arr[1] : ''
  })

  const buyText = computed(() => {
    if (!selectedVariant) return 'No variant selected'

    if (selectedVariant.value.available) {
      if (
        selectedVariant.value.inventory_management === 'shopify' &&
        selectedVariant.value.inventory_policy === 'continue' &&
        selectedVariant.value.inventory_quantity < 1
      ) {
        return 'Pre-order'
      }

      return 'Add to Cart'
    } else {
      return 'Out of Stock'
    }
  })

  const buyMessage = computed(() => {
    if (selectedVariant.value.inventory_management === 'shopify') {
      if (
        selectedVariant.value.inventory_policy === 'continue' &&
        selectedVariant.value.inventory_quantity < 1
      ) {
        const tagFragment = 'preorder-date-'
        const [dateTag] = product.tags.filter((tag) => tag.indexOf(tagFragment) > -1)
        let message = 'This item is available for pre-order.'
        if (dateTag) {
          const preorderDate = dateTag.substring(tagFragment.length)
          const dateCapitalized = preorderDate.charAt(0).toUpperCase() + preorderDate.substring(1)
          message += ` Estimated delivery during ${dateCapitalized}.`
        }
        return message
      }

      if (
        selectedVariant.value.inventory_quantity < 3 &&
        selectedVariant.value.inventory_quantity > 0
      ) {
        return `Only ${selectedVariant.value.inventory_quantity} left in stock`
      }
    }

    return ''
  })

  return {
    title,
    description,
    dimensions,
    shipping,
    buyText,
    buyMessage,
  }
}
