<template>
  <section v-if="relatedCollection && relatedProducts" class="related">
    <div class="inner">
      <div class="heading">
        <h2 class="title">
          More:<br />
          {{ relatedCollection.title }}
        </h2>
        <div class="link">
          <a :href="relatedCollection.url">Shop All</a>
        </div>
      </div>
      <div class="collection">
        <div class="blocks blocks-related">
          <div class="block" v-for="product in relatedProducts.slice(0, 3)" :key="product.handle">
            <a class="link" :href="product.url"></a>

            <div class="image">
              <ShopifyImage
                :src="product.featured_image.src"
                :alt="product.featured_image.alt"
                :ratio="`${product.featured_image.width}x${product.featured_image.height}`"
              />
            </div>
            <div class="caption">
              <div class="maker">{{ product.vendor }}</div>
              <div class="title">{{ product.title }}</div>
              <ShopifyPrice class="price" :amount="product.price" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import ShopifyImage from '../shopify/ShopifyImage.vue'
import ShopifyPrice from '../shopify/ShopifyPrice.vue'
import { useProductInject } from '../../context/useProductContext'

const { relatedCollection, relatedProducts } = useProductInject()
</script>
