<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 17.6 27.7"
    xml:space="preserve"
    width="17.6"
    height="27.700001"
  >
    <polygon
      class="st0"
      points="27.7,10 27.7,7.6 3.9,7.6 8.4,1.4 6.4,0 0,8.8 6.4,17.6 8.4,16.2 3.9,10 "
      id="polygon3712"
      transform="rotate(-90,13.85,13.85)"
      style="fill: #ffffff"
    />
  </svg>
</template>

<script lang="ts" setup></script>

<style scoped>
.st0 {
  fill: #ffffff;
}
</style>
