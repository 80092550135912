<template>
  <svg
    version="1.1"
    id="logo_svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 418 282.8"
    :style="logoStyles"
    xml:space="preserve"
  >
    <g>
      <g>
        <defs>
          <rect id="SVGID_1_" width="418" height="282.8" />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlink:href="#SVGID_1_" style="overflow: visible" />
        </clipPath>
        <path
          class="logo-path0"
          d="M33.2,68.3c-0.9-1-2-1.7-3.4-2.3c-1.4-0.6-3-0.8-4.8-0.8c-2,0-3.8,0.4-5.3,1.1c-1.5,0.8-2.8,1.8-3.7,3
			c-1,1.2-1.7,2.4-2.1,3.7c-0.4,1.3-0.7,2.9-0.7,4.7h23.4C36.5,73.8,35.4,70.6,33.2,68.3 M49.5,84.9c0,0.4,0,0.7-0.1,1.1
			c0,0.4,0,0.8,0,1.1H13.2c-0.1,2.1,0.2,4.1,0.9,5.9c0.7,1.9,1.7,3.4,3,4.7c1,0.9,2.1,1.7,3.4,2.3c1.3,0.7,2.8,1,4.6,1
			c1.9,0,3.4-0.2,4.6-0.6c1.2-0.4,2.3-1.1,3.3-2c1.3-1.3,2.1-2.8,2.6-4.6h12.5c-0.1,1.6-0.7,3.3-1.6,5.3c-0.9,1.9-1.9,3.5-3,4.7
			c-2.3,2.7-5,4.6-8.2,5.8c-3.2,1.2-6.5,1.8-10,1.8c-3.7,0-7-0.6-9.7-1.8c-2.7-1.2-5.2-2.8-7.3-4.8c-2.7-2.6-4.8-5.8-6.2-9.5
			C0.7,91.8,0,87.7,0,83.3c0-4.4,0.6-8.4,1.9-12.1c1.3-3.7,3.2-6.9,5.9-9.5c2-2.1,4.5-3.8,7.3-5c2.9-1.2,6.2-1.9,9.9-1.9
			c4,0,7.8,0.8,11.2,2.4c3.5,1.6,6.3,4.1,8.5,7.5c2,3,3.3,6,4,9.1c0.6,3.1,0.9,6.3,0.7,9.7V84.9z"
        />
      </g>
      <polygon
        class="logo-path0"
        points="84.7,110.2 70.9,110.2 50.8,56.1 64.5,56.1 78.1,94.2 90.9,56.1 104.3,56.1 	"
      />
      <g>
        <defs>
          <rect id="SVGID_3_" width="418" height="282.8" />
        </defs>
        <clipPath id="SVGID_4_">
          <use xlink:href="#SVGID_3_" style="overflow: visible" />
        </clipPath>
        <path
          class="logo-path0"
          d="M138.8,68.3c-0.9-1-2-1.7-3.4-2.3c-1.4-0.6-3-0.8-4.8-0.8c-2,0-3.8,0.4-5.3,1.1c-1.5,0.8-2.8,1.8-3.8,3
			c-1,1.2-1.7,2.4-2.1,3.7c-0.4,1.3-0.7,2.9-0.7,4.7h23.4C142,73.8,140.9,70.6,138.8,68.3 M155.1,84.9c0,0.4,0,0.7-0.1,1.1
			c0,0.4,0,0.8,0,1.1h-36.2c-0.1,2.1,0.2,4.1,0.9,5.9c0.7,1.9,1.7,3.4,3,4.7c1,0.9,2.1,1.7,3.4,2.3c1.3,0.7,2.8,1,4.6,1
			c1.9,0,3.4-0.2,4.6-0.6c1.2-0.4,2.3-1.1,3.3-2c1.3-1.3,2.1-2.8,2.6-4.6h12.5c-0.1,1.6-0.7,3.3-1.6,5.3c-0.9,1.9-1.9,3.5-3,4.7
			c-2.3,2.7-5,4.6-8.2,5.8c-3.2,1.2-6.5,1.8-10,1.8c-3.7,0-7-0.6-9.7-1.8c-2.7-1.2-5.2-2.8-7.3-4.8c-2.7-2.6-4.8-5.8-6.2-9.5
			c-1.4-3.7-2.1-7.7-2.1-12.2c0-4.4,0.6-8.4,1.9-12.1c1.3-3.7,3.2-6.9,5.9-9.5c2-2.1,4.5-3.8,7.3-5c2.9-1.2,6.2-1.9,9.9-1.9
			c4,0,7.8,0.8,11.2,2.4c3.5,1.6,6.3,4.1,8.5,7.5c2,3,3.3,6,4,9.1c0.6,3.1,0.9,6.3,0.7,9.7V84.9z"
        />
        <path
          class="logo-path0"
          d="M191.6,67.6c-4.6,0-8.1,1.5-10.4,4.4c-2.4,2.9-3.5,6.6-3.5,11v27.3h-13.2V56.1h11.1l1.2,8
			c1.3-3.1,3.3-5.3,6.2-6.7c2.9-1.4,5.9-2.1,9.1-2.1c1.4,0,2.7,0.1,4.1,0.3v12.4C194.5,67.7,193,67.6,191.6,67.6"
        />
        <path
          class="logo-path0"
          d="M230.9,119.5c-0.7,1.8-1.5,3.5-2.4,5.2c-0.9,1.6-2.1,3.1-3.4,4.3c-1.3,1.3-2.9,2.2-4.6,3
			c-1.8,0.7-3.8,1.1-6.2,1.1c-2.2,0-4-0.1-5.6-0.4c-1.6-0.3-3-0.6-4.4-0.9v-10.8c0.8,0.1,1.5,0.3,2.1,0.4c0.6,0.1,1.1,0.2,1.7,0.3
			c0.5,0.1,1.1,0.1,1.6,0.2c0.6,0,1.2,0,1.9,0c1.2,0,2.3-0.3,3.5-0.8c1.1-0.6,2.1-1.6,2.8-3.2l3.1-7.6l-21.3-54h14.3L228,95.2
			l13.5-39.1h13.2L230.9,119.5z"
        />
        <path
          class="logo-path0"
          d="M292.8,83.1c0-1.9-0.2-3.9-0.5-6c-0.3-2-0.9-3.9-1.8-5.6c-0.9-1.7-2.1-3.1-3.7-4.2c-1.6-1.1-3.7-1.6-6.2-1.6
			c-2,0-3.7,0.4-5.2,1.3c-1.4,0.8-2.6,1.8-3.6,3c-1.4,1.8-2.3,3.9-2.8,6.1c-0.5,2.3-0.7,4.6-0.7,7c0,2.4,0.2,4.7,0.7,7
			c0.5,2.3,1.4,4.4,2.8,6.2c1,1.2,2.2,2.2,3.6,3c1.4,0.8,3.1,1.3,5.2,1.3c2.6,0,4.6-0.5,6.2-1.6c1.6-1.1,2.8-2.5,3.7-4.2
			c0.9-1.7,1.5-3.6,1.8-5.7C292.6,87,292.8,85,292.8,83.1 M293.9,110.2l-0.8-5.9c-1.6,2.5-3.8,4.3-6.6,5.5c-2.8,1.1-5.7,1.7-8.6,1.7
			c-2.7,0-5.2-0.4-7.4-1.3c-2.2-0.8-4.2-1.9-5.8-3.3c-3.3-2.6-5.7-6-7.2-9.9c-1.6-4-2.3-8.6-2.3-13.7c0-5.3,0.8-10,2.4-14.1
			c1.6-4.1,4-7.4,7.3-10c1.7-1.4,3.6-2.5,5.8-3.2c2.2-0.8,4.7-1.1,7.4-1.1c3.5,0,6.4,0.5,8.8,1.5c2.4,1,4.2,2.2,5.4,3.6V35.1h13
			v75.2H293.9z"
        />
        <path
          class="logo-path0"
          d="M347,86.2l-13.7,1c-2,0.1-3.8,0.8-5.4,2c-1.6,1.2-2.4,2.9-2.4,5.1c0,2.1,0.8,3.7,2.3,4.9
			c1.6,1.2,3.3,1.8,5.4,1.8c4.1,0,7.4-1.1,10-3.2c2.6-2.1,3.9-5.2,3.9-9.2V86.2z M361.6,110.7c-0.8,0.2-2,0.4-3.6,0.4
			c-2.8,0-5-0.8-6.6-2.3c-1.5-1.5-2.4-3.3-2.6-5.3c-1.2,2.2-3.3,4.1-6.2,5.7c-2.9,1.6-6.3,2.4-10.2,2.4c-3.2,0-6-0.5-8.5-1.4
			c-2.5-0.9-4.5-2.2-6.1-3.7c-1.6-1.6-2.9-3.4-3.7-5.4c-0.8-2-1.2-4.2-1.2-6.4c0-2.6,0.5-4.9,1.4-6.9c0.9-2,2.2-3.7,3.9-5.2
			c1.7-1.5,3.6-2.6,5.8-3.4c2.2-0.8,4.6-1.3,7.2-1.5l15.9-1.1v-3.3c0-2.6-0.6-4.6-1.8-6.2c-1.2-1.6-3.7-2.3-7.3-2.3
			c-3.1,0-5.4,0.7-7.1,2c-1.7,1.3-2.6,3.2-2.8,5.7h-12.6c0.2-3.1,0.9-5.7,2.1-7.9c1.2-2.2,2.8-4,4.8-5.5c2-1.4,4.4-2.5,7-3.2
			c2.7-0.7,5.5-1,8.5-1c4.6,0,8.8,0.8,12.4,2.4c3.6,1.6,6.3,4.5,7.9,8.6c0.7,1.7,1.1,3.5,1.3,5.4c0.2,1.9,0.3,3.7,0.3,5.5v20.1
			c0,1.3,0.2,2.1,0.7,2.7c0.5,0.5,1.2,0.8,2.3,0.8c0.6,0,0.9,0,1.1-0.1c0.2-0.1,0.4-0.1,0.5-0.1v9.6
			C363.3,110.1,362.4,110.5,361.6,110.7"
        />
        <path
          class="logo-path0"
          d="M394.2,119.5c-0.7,1.8-1.5,3.5-2.4,5.2c-0.9,1.6-2.1,3.1-3.4,4.3c-1.3,1.3-2.9,2.2-4.6,3
			c-1.8,0.7-3.8,1.1-6.2,1.1c-2.2,0-4-0.1-5.6-0.4c-1.6-0.3-3-0.6-4.4-0.9v-10.8c0.8,0.1,1.5,0.3,2.1,0.4c0.6,0.1,1.1,0.2,1.7,0.3
			c0.5,0.1,1.1,0.1,1.6,0.2c0.6,0,1.2,0,1.9,0c1.2,0,2.3-0.3,3.5-0.8c1.1-0.6,2.1-1.6,2.8-3.2l3.1-7.6l-21.3-54h14.3l14.2,39.1
			l13.5-39.1H418L394.2,119.5z"
        />
        <path
          class="logo-path0"
          d="M36.8,248.4v-33.1c0-3.2-0.6-5.7-1.9-7.5c-1.2-1.8-3.6-2.7-7.1-2.7c-2.3,0-4.2,0.6-5.7,1.9
			c-1.5,1.3-2.7,2.9-3.6,4.9c-1,2.1-1.5,4.5-1.6,7.1c-0.1,2.6-0.2,5-0.2,7.3v22h-13v-54.1h10.9l0.9,7.1c1.6-2.9,3.9-5,6.8-6.4
			c3-1.4,6-2,9.1-2c2.9,0,5.5,0.5,7.8,1.5c2.3,1,4.2,2.3,5.7,4c1.6,1.7,2.8,3.7,3.6,6.1c0.8,2.4,1.2,5,1.2,7.8v36.1H36.8z"
        />
        <path
          class="logo-path0"
          d="M90.1,206.4c-0.9-1-2-1.7-3.4-2.3c-1.3-0.6-3-0.8-4.8-0.8c-2,0-3.8,0.4-5.3,1.1c-1.5,0.8-2.8,1.8-3.7,3
			c-1,1.2-1.7,2.4-2.1,3.7c-0.4,1.3-0.7,2.9-0.7,4.7h23.4C93.4,211.9,92.3,208.8,90.1,206.4 M106.5,223.1c0,0.4,0,0.7-0.1,1.1
			c0,0.4,0,0.8,0,1.1H70.2c-0.1,2.1,0.2,4.1,0.9,5.9c0.7,1.9,1.7,3.4,3,4.7c1,0.9,2.1,1.7,3.4,2.3c1.3,0.7,2.8,1,4.6,1
			c1.9,0,3.4-0.2,4.6-0.6c1.2-0.4,2.3-1.1,3.3-2c1.3-1.3,2.1-2.8,2.6-4.6h12.5c-0.1,1.6-0.7,3.3-1.6,5.3c-0.9,1.9-1.9,3.5-3,4.7
			c-2.3,2.7-5,4.6-8.2,5.8c-3.2,1.2-6.5,1.8-10,1.8c-3.7,0-7-0.6-9.7-1.8c-2.7-1.2-5.2-2.8-7.3-4.8c-2.7-2.6-4.8-5.8-6.2-9.5
			c-1.4-3.7-2.1-7.7-2.1-12.2c0-4.4,0.6-8.4,1.9-12.1c1.3-3.7,3.2-6.9,5.9-9.5c2-2.1,4.5-3.8,7.3-5c2.9-1.3,6.2-1.9,9.9-1.9
			c4,0,7.8,0.8,11.2,2.4c3.5,1.6,6.3,4.1,8.5,7.5c2,3,3.3,6,4,9.1c0.6,3.1,0.9,6.3,0.7,9.7V223.1z"
        />
        <path
          class="logo-path0"
          d="M147.1,206.4c-0.9-1-2-1.7-3.4-2.3c-1.4-0.6-3-0.8-4.8-0.8c-2,0-3.8,0.4-5.3,1.1c-1.5,0.8-2.8,1.8-3.7,3
			c-1,1.2-1.7,2.4-2.1,3.7c-0.4,1.3-0.7,2.9-0.7,4.7h23.4C150.3,211.9,149.2,208.8,147.1,206.4 M163.4,223.1c0,0.4,0,0.7-0.1,1.1
			c0,0.4,0,0.8,0,1.1h-36.2c-0.1,2.1,0.2,4.1,0.9,5.9c0.7,1.9,1.7,3.4,3,4.7c1,0.9,2.1,1.7,3.4,2.3c1.3,0.7,2.8,1,4.6,1
			c1.9,0,3.4-0.2,4.6-0.6c1.2-0.4,2.3-1.1,3.3-2c1.3-1.3,2.1-2.8,2.6-4.6h12.5c-0.1,1.6-0.7,3.3-1.6,5.3c-0.9,1.9-1.9,3.5-3,4.7
			c-2.3,2.7-5,4.6-8.2,5.8c-3.2,1.2-6.5,1.8-10,1.8c-3.7,0-7-0.6-9.7-1.8c-2.7-1.2-5.2-2.8-7.3-4.8c-2.7-2.6-4.8-5.8-6.2-9.5
			c-1.4-3.7-2.1-7.7-2.1-12.2c0-4.4,0.6-8.4,1.9-12.1c1.3-3.7,3.2-6.9,5.9-9.5c2-2.1,4.5-3.8,7.3-5c2.9-1.3,6.2-1.9,9.9-1.9
			c4,0,7.8,0.8,11.2,2.4c3.5,1.6,6.3,4.1,8.5,7.5c2,3,3.3,6,4,9.1c0.6,3.1,0.9,6.3,0.7,9.7V223.1z"
        />
        <path
          class="logo-path0"
          d="M209,221.2c0-1.9-0.2-3.9-0.5-6c-0.3-2-0.9-3.9-1.8-5.6c-0.9-1.7-2.1-3.1-3.7-4.2c-1.6-1.1-3.7-1.6-6.2-1.6
			c-2,0-3.7,0.4-5.2,1.3c-1.4,0.8-2.6,1.8-3.6,3c-1.4,1.8-2.3,3.9-2.8,6.1c-0.5,2.3-0.7,4.6-0.7,7c0,2.4,0.2,4.7,0.7,7
			c0.5,2.3,1.4,4.4,2.8,6.2c1,1.2,2.2,2.2,3.6,3c1.4,0.8,3.1,1.2,5.2,1.2c2.6,0,4.6-0.5,6.2-1.6c1.6-1.1,2.8-2.5,3.7-4.2
			c0.9-1.7,1.5-3.6,1.8-5.7C208.9,225.1,209,223.1,209,221.2 M210.2,248.4l-0.8-5.9c-1.6,2.5-3.8,4.3-6.6,5.5
			c-2.8,1.1-5.7,1.7-8.6,1.7c-2.7,0-5.2-0.4-7.4-1.3s-4.2-1.9-5.8-3.3c-3.3-2.6-5.7-6-7.2-9.9c-1.6-4-2.3-8.6-2.3-13.7
			c0-5.3,0.8-10,2.4-14.1c1.6-4.1,4-7.4,7.3-10c1.7-1.4,3.6-2.5,5.8-3.2c2.2-0.8,4.7-1.1,7.4-1.1c3.5,0,6.4,0.5,8.8,1.5
			c2.4,1,4.2,2.2,5.4,3.6v-24.8h13v75.2H210.2z"
        />
        <path
          class="logo-path0"
          d="M275.5,231.6c0,2.6-0.4,4.9-1.3,6.9c-0.9,1.9-2,3.6-3.4,5c-2.2,2.2-4.9,3.8-8.1,4.8c-3.2,1-6.7,1.5-10.4,1.5
			c-3.5,0-6.7-0.5-9.7-1.5c-3-1-5.6-2.6-7.8-4.7c-1.6-1.5-3-3.4-4.1-5.5c-1.1-2.1-1.7-4.5-1.7-7.1h12.2c0,1.2,0.3,2.4,0.8,3.5
			c0.6,1.2,1.2,2.1,2.1,2.8c1,0.8,2.2,1.4,3.5,1.8s2.9,0.6,4.7,0.6c1.1,0,2.3-0.1,3.6-0.3c1.3-0.2,2.5-0.5,3.6-1
			c1.1-0.5,2-1.2,2.8-2.1c0.7-0.9,1.1-2.1,1.1-3.5c0-1.6-0.5-2.9-1.5-3.9c-1-1-2.3-1.6-4-1.9c-2.4-0.3-4.9-0.6-7.5-0.9
			c-2.6-0.2-5.1-0.6-7.5-1.2c-1.9-0.4-3.6-1.1-5-2c-1.5-0.9-2.8-2-3.9-3.3c-1.1-1.3-2-2.7-2.6-4.3c-0.6-1.6-0.9-3.2-0.9-4.9
			c0-2.8,0.5-5.2,1.6-7.1c1-2,2.4-3.7,4-5c2-1.7,4.4-3.1,7.2-4c2.8-0.9,5.9-1.4,9.3-1.4c3.3,0,6.4,0.5,9.4,1.6c3,1,5.5,2.6,7.5,4.8
			c1.5,1.6,2.6,3.3,3.5,5.3c0.9,1.9,1.3,3.9,1.3,5.9H262c0-1-0.2-2-0.6-2.8c-0.4-0.8-0.9-1.6-1.6-2.3c-0.8-0.8-1.8-1.4-3.1-1.8
			c-1.3-0.5-2.6-0.7-4-0.7c-1,0-1.9,0-2.8,0.1c-0.9,0.1-1.8,0.3-2.8,0.7c-1.2,0.5-2.2,1.2-3,2.2c-0.8,1-1.2,2.2-1.2,3.5
			c0,1.9,0.7,3.3,2,4c1.3,0.7,2.8,1.2,4.2,1.4c1.3,0.1,2.3,0.3,3.1,0.4l2.5,0.3c0.8,0.1,1.7,0.2,2.6,0.4c0.9,0.1,2,0.3,3.3,0.5
			c4.3,0.7,7.8,2.3,10.6,4.9C274.1,223.8,275.5,227.3,275.5,231.6"
        />
      </g>
      <rect
        :x="hamPos('x', 1)"
        :y="hamPos('y', 1)"
        class="logo-path1 logo-hamburger1"
        width="45.8"
        height="5.9"
      />
      <rect
        :x="hamPos('x', 2)"
        :y="hamPos('y', 2)"
        class="logo-path1 logo-hamburger2"
        width="45.8"
        height="5.9"
      />
      <rect
        :x="hamPos('x', 3)"
        :y="hamPos('y', 3)"
        class="logo-path1 logo-hamburger3"
        width="45.8"
        height="5.9"
      />
      <rect x="0" class="logo-hamburger-hitbox" width="60" height="66" @click.prevent="onClick" />

      <a class="logo-link" @click="onClick">
        <rect x="0" class="logo-link-hitbox" width="418" height="300" />
      </a>

      <g>
        <path
          class="logo-path0"
          d="M292.8,198.5h-2.1v2.6h2.1c0.9,0,1.4-0.5,1.4-1.3C294.3,199.1,293.7,198.5,292.8,198.5 M294.2,205.9l-1.5-3.3
			h-1.9v3.3h-1.6v-8.8h3.7c2.1,0,3.1,1.3,3.1,2.7c0,1.2-0.6,2.1-1.6,2.5l1.6,3.6H294.2z M285.1,201.8c0,3.9,3.2,7,7,7
			c3.9,0,7-3.1,7-7c0-3.9-3.2-7-7-7C288.3,194.8,285.1,198,285.1,201.8 M283.2,201.8c0-5,4-9,9-9c5,0,9,4,9,9c0,5-4,9-9,9
			C287.2,210.9,283.2,206.8,283.2,201.8"
        />
      </g>
    </g>
  </svg>
</template>

<script lang="ts" setup>
import { useHeaderInject } from '../../context/useHeaderContext'

const { hamburgerDimensions, logoStyles, hamburgerActive, menuActive, toggleMenu } = useHeaderInject()
const hamPos = hamburgerDimensions

const onClick = () =>
  hamburgerActive.value || menuActive.value ? toggleMenu() : (window.location.href = '/')
</script>

<style lang="scss">
.logo-path0,
.logo-path1 {
  fill: v-bind(color);
}

.logo-hamburger-hitbox {
  cursor: pointer;
}

.logo-link-hitbox {
  cursor: pointer;
}
</style>
