<template>
  <div class="overlay overlay-mmenu" :class="{ active: current == 'MOBILE' }">
    <div class="container">
      <nav class="close" @click="setOverlay('')"><i></i><i></i></nav>

      <nav class="search">
        <form action="/search">
          <input type="text" name="q" placeholder="Search" required />
          <button><SvgSearchGrey color="#fff" /></button>
        </form>
      </nav>

      <div class="menus">
        <ul>
          <li>
            <ShopifyLinks :links="linklists['header-mobile-left']?.links">
              <li><span class="line"></span></li>
              <li>
                <a v-if="isLoggedIn" href="/account">Account</a>
                <a v-else href="/account/login">Login</a>
              </li>
              <li>
                <a href="/a/wishlists">Your&nbsp;Edit</a>
              </li>
              <li class="currency">
                <span class="a">{{ currencySymbol }} {{ currencyCode }}</span>
                <ul>
                  <li v-for="currency in currencies" :key="currency">
                    <span @click.prevent="() => setCurrency(currency)">{{ currency }}</span>
                  </li>
                </ul>
              </li>
            </ShopifyLinks>
          </li>
          <li>
            <ShopifyLinks :links="linklists['header-mobile-right']?.links" />
          </li>
        </ul>
      </div>
    </div>
    <div class="close-panel" @click="setOverlay('')"></div>
  </div>
</template>

<script lang="ts" setup>
import { useAppInject } from '../../context/useAppContext'
import { useCurrencyInject } from '../../context/useCurrencyContext'
import { useOverlayInject } from '../../context/useOverlayContext'
import ShopifyLinks from '../shopify/ShopifyLinks.vue'
import SvgSearchGrey from '../svg/SvgSearchGrey.vue'

const { linklists, isLoggedIn } = useAppInject()
const { current, setOverlay } = useOverlayInject()
const { currencyCode, currencies, setCurrency, currencySymbol } = useCurrencyInject()
</script>
