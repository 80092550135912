<template>
  <section class="blog">
    <div class="blocks blocks-journal">
      <BlogJournalCard
        v-for="(article, index) in blog.articles"
        :article="article"
        :index="index"
        :key="article.handle"
      />
    </div>
    <BlogPagination />
  </section>
</template>

<script lang="ts" setup>
import BlogJournalCard from './BlogJournalCard.vue'
import { useBlogInject } from '../../context/useBlogContext'
import BlogPagination from './BlogPagination.vue'

const { blog } = useBlogInject()
</script>
