import { openDB, IDBPDatabase } from 'idb'

const DB_NAME = 'shopCache'
const DB_VERSION = 1
const ONE_HOUR = 60 * 60 * 1000

interface CachedData<T> {
  key: string
  data: T
  timestamp: number
}

type CacheStore = 'collections' | 'products'

// Initialize DB
const createDB = () =>
  openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      db.createObjectStore('collections', { keyPath: 'key' })
      db.createObjectStore('products', { keyPath: 'key' })
    },
  })

// Lazy DB initialization
let dbPromise: Promise<IDBPDatabase> | null = null
const getDB = () => {
  if (!dbPromise) {
    dbPromise = createDB()
  }
  return dbPromise
}

const isExpired = (timestamp: number) => Date.now() - timestamp > ONE_HOUR

const getFromCache = async <T>(store: CacheStore, key: string): Promise<T | null> => {
  const db = await getDB()
  const cached = (await db.get(store, key)) as CachedData<T> | undefined

  if (!cached) return null
  if (isExpired(cached.timestamp)) {
    await db.delete(store, key)
    return null
  }

  return cached.data
}

const setToCache = async <T>(store: CacheStore, key: string, data: T): Promise<void> => {
  const db = await getDB()
  const cacheData: CachedData<T> = {
    key,
    data,
    timestamp: Date.now(),
  }
  await db.put(store, cacheData)
}

export const clearExpiredCache = async (): Promise<void> => {
  const db = await getDB()
  const stores: CacheStore[] = ['collections', 'products']

  await Promise.all(
    stores.map(async (store) => {
      const keys = await db.getAllKeys(store)
      await Promise.all(
        keys.map(async (key) => {
          const cached = (await db.get(store, key)) as CachedData<any>
          if (isExpired(cached.timestamp)) {
            await db.delete(store, key)
          }
        })
      )
    })
  )
}

export const clearCache = async (): Promise<void> => {
  const db = await getDB()
  await Promise.all(['collections', 'products'].map((store) => db.clear(store)))
}

export const fetchWithCache = async <T>(
  store: CacheStore,
  key: string,
  fetcher: () => Promise<T>
): Promise<T> => {
  const cached = await getFromCache<T>(store, key)
  if (cached) return cached

  const data = await fetcher()
  await setToCache(store, key, data)
  return data
}
