<template>
  <section class="makers">
    <div class="header">
      <h1>The Makers</h1>
    </div>
    <nav>
      <ul>
        <li v-for="(makers, letter) in letters" :key="letter">
          <h2 class="letter" v-text="letter"></h2>
          <ul>
            <li v-for="maker in makers" :key="maker.title">
              <a :href="maker.url" v-text="maker.title"></a>
              <div class="image" :style="imageStyle(maker)"></div>
            </li>
            <li>&nbsp;</li>
          </ul>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import { useAppInject } from '../../context/useAppContext'
import { shopifyImage } from '../../helpers'
import { LiquidLink } from '../../types/liquid'

const { linklists } = useAppInject()

export type Maker = LiquidLink & {
  imageSrc: string
  imageWidth: number
  imageHeight: number
  loaded: boolean
}

const initialMakers =
  linklists['makers'].links.map((link) => {
    const image = link.object.featured_image
    return {
      ...link,
      imageSrc: shopifyImage({ src: image.src, width: 600 }),
      imageWidth: image.width,
      imageHeight: image.height,
      loaded: false,
    }
  }) || []

const makers = ref<Maker[]>(initialMakers)

const letters = computed(() => {
  return makers.value.reduce((acc, maker) => {
    const letter = maker.title[0].toUpperCase()
    if (!acc[letter]) {
      acc[letter] = []
    }
    acc[letter].push(maker)
    return acc
  }, {} as Record<string, Maker[]>)
})

const menuStyles = ref({})

const imageStyle = computed(() => (maker: Maker) => {
  const hashCode = (str: string): number => {
    let ret = 0
    for (let i = 0; i < str.length; i++) {
      ret = (31 * ret + str.charCodeAt(i)) << 0
    }
    return ret
  }

  const code = hashCode(maker.imageSrc)
  if (menuStyles.value[code] !== undefined) {
    return menuStyles.value[code]
  } else {
    const windowHeight = window.innerHeight
    const windowWidth = window.innerWidth
    const imageWidth = (windowWidth / 30) * 6
    const imageHeight = (maker.imageWidth / maker.imageHeight) * imageWidth
    const paddingTop = 100
    const paddingLeft = (windowWidth / 30) * 3
    const paddingRight = (windowWidth / 30) * 3
    const imageTop = Math.random() * (windowHeight - imageHeight - 100 - paddingTop) + paddingTop
    const imageLeft =
      Math.random() * (windowWidth - imageWidth - paddingRight - paddingLeft) + paddingLeft

    const v = {
      top: `${imageTop}px`,
      left: `${imageLeft}px`,
      width: `${imageWidth}px`,
      height: `${imageHeight}px`,
      'background-image': `url(${maker.imageSrc})`,
    }
    menuStyles.value[code] = v
    return v
  }
})

// onMounted(() => {
//   Object.values(letters.value)
//     .flat()
//     .forEach((maker: any) => {
//       if (maker.loaded) return
//       const img = new Image()
//       img.onload = () => {
//         maker.width = img.width
//         maker.height = img.height
//         maker.loaded = true
//       }
//       img.src = maker.src
//     })
// })
</script>
