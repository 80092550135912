import { provide, inject, Ref, ref } from 'vue'
import {
  LiquidLinkList,
  LiquidProduct,
  LiquidSettings,
  LiquidShop,
  LiquidTemplate,
} from '../types/liquid'
import { useStore } from 'vuex'
import { useLayoutClassesContext } from './useLayoutClasses'
import { useHeaderContext } from './useHeaderContext'
import { useOverlayContext } from './useOverlayContext'
import { useCurrencyContext } from './useCurrencyContext'
import { useCartContext } from './useCartContext'
import { LiquidCart } from '../types/liquid'
import { useNewsletterContext } from './useNewsletterContext'

export type AppContextProps = {
  currencyCode: string
  shop: LiquidShop
  cart: LiquidCart
  giftwrapProduct: LiquidProduct
  settings: LiquidSettings
  linklists: LiquidLinkList[]
  template: LiquidTemplate
  templateClass: string
  isLoggedIn: boolean
  userId?: string
}

export type AppContextType = AppContextProps & {
  linklists: any
  scrollToTop: () => void
  isTemplate: (match: string) => boolean
  classes: Ref<string>
}

export const APP_INJECTION_KEY = Symbol('APP')

export const useAppContext = ({
  currencyCode,
  shop,
  cart,
  giftwrapProduct,
  settings,
  linklists: linkslistsArray,
  template,
  templateClass,
  isLoggedIn,
  userId,
}: AppContextProps) => {
  useStore()

  const isTemplate = (match: string) => template.name.indexOf(match) > -1

  useCurrencyContext(currencyCode)
  useCartContext(cart, giftwrapProduct)
  const { setOverlay, current: overlay } = useOverlayContext()
  const { classes } = useLayoutClassesContext(templateClass, overlay)
  useHeaderContext({ isTemplate })
  useNewsletterContext({ isLoggedIn })

  const linklists = linkslistsArray.reduce((c: AppContextType['linklists'], v: LiquidLinkList) => {
    c[v.handle] = v
    return c
  }, {})

  const scrollToTop = () => window.scrollTo(0, 0)

  if (window.location.hash == '#cart') setOverlay('CART')
  // if (!isTemplate('index')) setMobileLogo(true)

  const values = {
    linklists,
    shop,
    cart,
    giftwrapProduct,
    settings,
    template,
    isLoggedIn,
    userId,

    templateClass,
    classes,

    scrollToTop,
    isTemplate,
    currencyCode,
  }

  provide<AppContextType>(APP_INJECTION_KEY, values)
  return values
}

export const useAppInject = () => {
  const context = inject<AppContextType>(APP_INJECTION_KEY)
  if (!context) throw new Error()
  return context
}
