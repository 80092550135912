<template>
  <div class="container">
    <button
      class="button button--overlay"
      :class="{ 'button--overlay-left': !ifPrev, 'button--overlay-right': !ifNext }"
      aria-label="Open Overlay"
      @click="() => setOverlay('PRODUCT')"
    ></button>
    <button @click="onPrev" class="button button--prev" v-if="ifPrev"></button>
    <button @click="onNext" class="button button--next" v-if="ifNext"></button>
  </div>
</template>

<script lang="ts" setup>
import { computed, unref } from 'vue'
import { useSlideshowInject } from '../../context/useSlideshowContext'
import { useOverlayInject } from '../../context/useOverlayContext'

const { emblaApi, index, total } = useSlideshowInject()
const { setOverlay } = useOverlayInject()

const onPrev = () => {
  if (emblaApi.value) {
    emblaApi.value.scrollPrev()
  }
}

const onNext = () => {
  if (emblaApi.value) {
    emblaApi.value.scrollNext()
  }
}

const ifPrev = computed(() => unref(index) > 0)
const ifNext = computed(() => unref(index) < unref(total) - 1)
</script>

<style lang="scss" scoped>
@import '~styles/base';

.container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  &:focus,
  &:hover {
    .button {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.button {
  position: absolute;
  transition: opacity 0.3s ease;
  height: 100%;
  z-index: 10;

  @include breakpoint('l+') {
    opacity: 0;
  }

  @include breakpoint('l') {
    pointer-events: auto;
  }

  &--overlay {
    left: 30%;
    right: 30%;
    cursor: crosshair;

    &.button--overlay-left {
      left: 0;
    }

    &.button--overlay-right {
      right: 0;
    }
  }

  &--prev {
    right: 70%;
    left: 0;
    cursor: w-resize;
  }

  &--next {
    left: 70%;
    right: 0;
    cursor: e-resize;
  }
}
</style>
