import axios from 'axios'
// import qs from 'qs';

const baseURL = '/a/wishlists/'

const axios_get = {
  dataType: 'json',
  baseURL,
  // contentType: 'application/json',
  // params: {
  //   view: 'api'
  // }
}

const AxiosGet = async function (url, overrides = {}) {
  var settings = _.merge({}, axios_get, overrides)

  var response = await axios.get(url, settings)
  return response.data
}

const axios_post = {
  dataType: 'json',
  baseURL,
}

const AxiosPost = async function (url, data, overrides = {}) {
  var settings = _.merge({}, axios_post, overrides)
  let method = data.method || 'POST'

  var response = await axios.request({
    url,
    method,
    data: data.data,
    ...settings,
  })

  return response.data
}

export { AxiosGet, AxiosPost }
