import { Module } from 'vuex'
import { RootState } from './index'

export type LoginState = {
  currentForm: string
}

const login: Module<LoginState, RootState> = {
  namespaced: true,
  state: (): LoginState => ({
    currentForm: 'login',
  }),
  mutations: {
    setForm(state, form: string | number = -1) {
      if (form !== -1) state.currentForm = form as string
    },
  },
  actions: {
    setForm({ commit }, form: string) {
      commit('setForm', form)
    },
    selectTab({ commit }) {
      const hash = location.hash.slice(1)
      if (hash === 'recover') {
        commit('setForm', 'forgot')
      }
    },
  },
  getters: {
    showFormClass: (state) => (form: string): string => {
      return state.currentForm === form ? 'active' : ''
    },
  },
}

export default login
