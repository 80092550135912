import { provide, inject, Ref, ref, computed } from 'vue'
import _ from 'lodash'
import { LiquidCollection, LiquidPagination, LiquidProductSimple } from '../types/liquid'

export type CollectionContextProps = {
  collection: LiquidCollection
  products: LiquidProductSimple[]
  pagination: LiquidPagination
}

export type CollectionContextType = CollectionContextProps & {
  view: Ref<number>
  gapCloseOdd: Ref<boolean>
}

export const APP_INJECTION_KEY = Symbol('COLLECTION')

export const useCollectionContext = ({ collection, products, pagination }: CollectionContextProps) => {
  const view = ref(2)
  const gapCloseOdd = ref(true)

  const cardDimensions = (product: LiquidProductSimple): string => {
    const tag = product.tags.find((t) => t.startsWith('layout-'))
    const layout = tag ? tag.replace('layout-', '') : 'portrait-small'

    if (layout.indexOf('landscape') > -1) {
      return '800x500_crop_center'
    } else if (layout === 'portrait-large') {
      return '900x1200_crop_center'
    } else {
      return '600x800_crop_center'
    }
  }

  const values = {
    pagination,
    collection,
    products,
    view,
    gapCloseOdd,
    cardDimensions,
  }
  provide<CollectionContextType>(APP_INJECTION_KEY, values)
  return values
}

export const useCollectionInject = () => {
  const context = inject<CollectionContextType>(APP_INJECTION_KEY)
  if (!context) throw new Error('CollectionContext not provided')
  return context
}
