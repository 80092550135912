<template>
  <img :src="url" ref="el" :class="`image ${active ? 'active' : 'loading'}`" @load="handleImageLoad" />
</template>

<script lang="ts" setup>
import { useElementSize } from '@vueuse/core'
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { ratioAsNumber, shopifyImageRatio } from '../../helpers/shopify'

const props = withDefaults(
  defineProps<{
    src: string
    ratio?: string
  }>(),
  {
    // ratio: '1x1',
  }
)

const el = ref<null | HTMLImageElement>(null)
const active = ref(false)
const { width: elWidth } = useElementSize(el)
let observer: IntersectionObserver | null = null

const size = computed(() => {
  const ratioNumber = props?.ratio && `${props?.ratio}`.indexOf('x') > -1 && ratioAsNumber(props.ratio)
  if (elWidth.value == 0) {
    const blurWidth = 600
    return {
      width: blurWidth,
      height: ratioNumber ? ratioNumber * blurWidth : blurWidth,
    }
  } else {
    return {
      width: elWidth.value,
      height: ratioNumber ? ratioNumber * elWidth.value : elWidth.value,
    }
  }
})

const url = computed(() => {
  const { url: src } = shopifyImageRatio(props.src, {
    modifiers: {
      width: size.value.width,
      ratio: props.ratio || '',
    },
  })
  return src
})

const handleImageLoad = () => {
  active.value = true
}

const checkImageLoaded = () => {
  if (!el.value) return

  // Handle cached images
  if (el.value.complete && el.value.naturalHeight !== 0) {
    active.value = true
  }
}

onMounted(() => {
  // Set up intersection observer to detect when image enters viewport
  observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          checkImageLoaded()
          // Once we've checked, we can disconnect the observer
          observer?.disconnect()
        }
      })
    },
    {
      threshold: 0.1, // Trigger when even 10% of the image is visible
    }
  )

  if (el.value) {
    observer.observe(el.value)
  }

  // Initial check for cached images
  checkImageLoaded()
})

onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect()
  }
})
</script>

<style lang="scss" scoped>
.image {
  opacity: 0;
  transition: opacity 0.1s ease-out;
  &.active {
    opacity: 1;
  }
}
</style>
