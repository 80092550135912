import { AxiosGet, AxiosPost } from './defaults';
import moment from 'moment';
import _ from 'lodash';

export default {
  async getAll(d) {
    // var products = await AxiosGet(`/products.json`);
    let products = [];
    let hasNext = true;
    let page = 1;
    let limit = 50;

    if(window.localStorage.getItem('all_products') ) {
      const savedProducts = JSON.parse(window.localStorage.getItem('all_products'));
      if(!moment(savedProducts.expire).isBefore(moment())) {
        return savedProducts.products;
      }
    }

    while(hasNext) {
      let response = await AxiosGet(`/collections/all`, {
        raw: true,
        params: { 
          view: 'json',
          limit, 
          page,
        },
      });

      if(response.data.products.length > 0) {
        products = [
          ...products,
          ...response.data.products,
        ];
      } else {
        hasNext = false;
      }
      
      page += 1;

      if(page > 10) hasNext = false;
    }

    window.localStorage.setItem('all_products', JSON.stringify({
      products: _.uniq(products),
      expire:   moment().add(5, 'minutes').format(),
    }))

    return products;
  },
  async getByHandle(d) {
    const product = await AxiosGet(`/products/${d.handle}?view=json`);

    //Handle parent
    // var parent_tag = product.tags.find((v) => v.startsWith('parent-'));

    // if(!!parent_tag) {
    //   var name = parent_tag.replace('parent-','');
    //   product.parent = {
    //     title: name.toUpperCase(),
    //     url: `/collections/${name}`,
    //   }
    // }

    return product;
  },
  async fetchAllInCollection(collection_handle) {
    let products = [];
    let hasNext = true;
    let page = 1;
    let limit = 50;

    if(window.localStorage.getItem(`${collection_handle}_products`) ) {
      const savedProducts = JSON.parse(window.localStorage.getItem(`${collection_handle}_products`));
      if(!moment(savedProducts.expire).isBefore(moment())) {
        return savedProducts.products;
      }
    }

    while(hasNext) {
      let response = await AxiosGet(`/collections/${collection_handle}`, {
        raw: true,
        params: { 
          view: 'json',
          limit, 
          page,
        },
      });

      if(response.data.products.length > 0) {
        products = [
          ...products,
          ...response.data.products,
        ];
      } else {
        hasNext = false;
      }
      
      page += 1;

      if(page > 10) hasNext = false;
    }

    window.localStorage.setItem(`${collection_handle}_products`, JSON.stringify({
      products: _.uniq(products),
      expire:   moment().add(5, 'minutes').format(),
    }))

    return products;
  },
  async fetchInCollection(collection_handle, limit = 50) {
    let response = await AxiosGet(`/collections/${collection_handle}`, {
      raw: true,
      params: { 
        view: 'json',
        limit,
      },
    });

    return response.data.products;
  },
}
