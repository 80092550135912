import { provide, inject, Ref, ref, computed } from 'vue'
import { useStore } from 'vuex'

export type LayoutClassesContextType = {
  invert: Ref<boolean>
  classes: Ref<string>
}

export const LAYOUT_INJECTION_KEY = Symbol('LAYOUT')

export const useLayoutClassesContext = (template: string, overlay: Ref<string>) => {
  const { state } = useStore()
  const invert = ref(false)

  const formattedClasses = computed(() => {
    const windowHeight = state.screen.resizeHeightCurrent
    const scrollTop = state.screen.scrollTopCurrent
    const scrollBottom = scrollTop + windowHeight

    const classes: string[] = ['loaded', 'layout', 'app']

    const footerEl = document.getElementById('ref_footer')
    if (footerEl) {
      const elTop = footerEl.offsetTop
      if (scrollBottom > elTop && state.overlay.current != 'gallery') {
        classes.push('unstick-sidebars')
      }
    }

    if (invert.value) classes.push('invert')

    if (overlay.value !== '') classes.push(`overlay-${overlay.value}`)

    return [...classes].join(' ')
  })

  const values = {
    invert,
    classes: formattedClasses,
  }

  provide<LayoutClassesContextType>(LAYOUT_INJECTION_KEY, values)
  return values
}

export const useLayoutClassesInject = () => {
  const context = inject<LayoutClassesContextType>(LAYOUT_INJECTION_KEY)
  if (!context) throw new Error('LayoutClassesContext not provided')
  return context
}
