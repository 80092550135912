<template>
  <div class="details" v-if="wishlist"> 
    <div class="guest" v-if="mode == 'guest'">
      <h2>How it works</h2>
      <p>
        Add an item to cart to purchase, you
        can have this item shipped to yourself,
        or directly to your recipient using their
        address provided below in check out.
      </p>
      <div v-if="getWishlistAddress">
        <p>
          Delivery Address:<br> 
          {{ getWishlistAddress }}
        </p>
      </div>
    </div>
    <div class="visitor" v-else>
      <h2 v-html="getWishlistName"></h2>
      <p>
        Welcome to Your Edit. Here we invite you to
        personally curate your own collection. Use
        Your Edit to keep track of products you have
        your eye on, or to share your wish list.
      </p> 
      <div v-if="isShareActive" class="share">
        <button @click.prevent="show()">Share your Edit</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mode: {},
    wishlist: {},
    bus: {},
  },
  computed: {
    getWishlistName() {
      let name = "Your Edit";
      if(this.mode == 'guest' && !!this.wishlist.username) {
        name += `:<br>${this.wishlist.title}`;
      }
      return name;
    },
    getWishlistAddress() {
      const a = this.wishlist.address;
      return (!!a && a != "") ? a : false
    },
    isShareActive() {
      if(!this.mode == 'visitor') return false;

      //Share wont show unless the edit has a title
      if(!this.wishlist.title) return false;

      // return `mailto:?subject=Everyday Needs - My Edit&body=https://everyday-needs.com/a/wishlists/${this.getWishlistID}`
      return true;
    },
  },
  methods: {
    show() {
      this.bus.$emit('overlay/show', 'wishlist_share');
    },
  },
}
</script>
