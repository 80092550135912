<template>
  <div class="wishlist-add" v-if="!!loaded && isLoggedIn">
    <button class="a" @click="update()">
      <span v-if="status == 'add'">+ Add to Your Edit</span>
      <span v-if="status == 'adding'">+ Adding to Your Edit</span>
      <span v-if="status == 'added'">+ Added to Your Edit</span>
      <span v-if="status == 'remove'">- Remove from Your Edit</span>
      <span v-if="status == 'removing'">- Removing from Your Edit</span>
      <span v-if="status == 'removed'">- Removed from Your Edit</span>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, unref } from 'vue'
import store from '../../store'
import { useAppInject } from '../../context/useAppContext'
import { useProductInject } from '../../context/useProductContext'

const { isLoggedIn } = useAppInject()
const { product, selectedVariant: selectedVariantRef } = useProductInject()
const selectedVariant = unref(selectedVariantRef)

const status = ref('')
const loaded = ref(false)

const items = store.state.wishlists.items

const inWishlist = computed(() => {
  return items.find((v) => v.shopify_variant.id == selectedVariant.id)
})

const getFormatted = computed(() => {
  return {
    quantity: 1,
    variant_id: selectedVariant.id,
    variant_title: selectedVariant.title,
    product_id: product.id,
    product_handle: product.handle,
    product_title: product.title,
  }
})

onMounted(async () => {
  // await store.dispatch('wishlists/load')
  const inWishlist = items.find((v) => v.shopify_variant.id == selectedVariant.id)
  status.value = !!inWishlist ? 'remove' : 'add'
  loaded.value = true
})

const update = async () => {
  const item = getFormatted

  if (!!inWishlist.value) {
    status.value = 'removing'
    await store.dispatch('wishlists/removeItem', item.value.variant_id)
    status.value = 'removed'
    setTimeout(() => (status.value = 'add'), 2000)
  } else {
    status.value = 'adding'
    await store.dispatch('wishlists/addItem', item)
    status.value = 'added'
    setTimeout(() => (status.value = 'remove'), 2000)
  }
}
</script>
