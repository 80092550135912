<template>
        <img v-bind:src="showImage(ready)" v-bind:class="{active:loaded}"/>
</template>

<script>
export default {
      props: ['queue', 'window', 'src','size'],
      mounted: function() {
        this.init();
      },
      data: function() {
        return {
          ready: false,
          loaded: false
        }
      },
      methods: {
        init: function() {
          this.ready = true
        },
        getSrc: (url, format) => {
          //Split url by '.'
          var dot_pos = (~-url.lastIndexOf(".") >>> 0) + 1;
          // Insert format in string
          return [url.slice(0,dot_pos), `_${format}`, url.slice(dot_pos)].join('');
        },
        showImage: function(ready) {
          if(!ready) return false;
          if(this.loaded) return this.getSrc(this.src,this.size);

          var $el           = $(this.$el);
          var queue         = this.queue;
          var el_top        = $el.offset().top;
          var scroll_top    = this.window.scroll.top.current;
          var window_height = this.window.resize.height.current;
          var lookahead     = scroll_top + (2*window_height);
          var lookbehind    = scroll_top - (2*window_height);

          if (el_top < lookahead && el_top > lookbehind) {
            this.loaded = true;
            return this.getSrc(this.src,this.size);
          }
          
          return false;
        }
      },
    }
</script>