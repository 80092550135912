import { ActionContext } from 'vuex'
import WishlistApi from '../api/wishlist'
import ProductApi from '../api/products'
import { RootState } from './index'

interface WishlistItem {
  product_handle: string
  variant_id: number
}

interface ShopifyVariant {
  id: number
  inventory_policy: string
  inventory_quantity: number
}

interface ShopifyProduct {
  variants: ShopifyVariant[]
}

interface FormattedWishlistItem {
  wishlist_item: WishlistItem
  shopify_product: ShopifyProduct
  shopify_variant: ShopifyVariant & { available: boolean }
}

export type WishlistState = {
  loaded: boolean
  type: string
  identifier: string | false
  userStatus: string
  wishlist: any
  items: FormattedWishlistItem[]
  data?: any
}

export default {
  namespaced: true,
  state: (): WishlistState => ({
    loaded: false,
    type: 'user',
    identifier: false,
    userStatus: '',
    wishlist: false,
    items: [],
  }),
  actions: {
    async set({ dispatch, commit }, wishlist: any) {
      commit('setUserStatus', 'visiting')
      commit('setWishlist', wishlist)
      await dispatch('fetchItems')
      commit('setLoaded', true)
    },
    async load({ dispatch, commit }, { userId, isLoggedIn }: { userId?: string; isLoggedIn: boolean }) {
      const wasLoggedIn = window.localStorage.getItem('loggedIn') === 'true'
      let status = ''

      if (typeof window.localStorage.getItem('loggedIn') === 'undefined') {
        window.localStorage.setItem('loggedIn', String(isLoggedIn))
        status = isLoggedIn ? 'logging_in' : 'logging_out'
      } else {
        const identifier = userId
        commit('setIdentifier', identifier)

        if (!isLoggedIn && wasLoggedIn) status = 'logging_out'
        else if (isLoggedIn && !wasLoggedIn) status = 'logging_in'
        else if (!isLoggedIn && !wasLoggedIn) status = 'logged_out'
        else if (isLoggedIn && wasLoggedIn) status = 'loggedIn'
      }

      commit('setUserStatus', status)

      if (status === 'logging_out') await dispatch('onLoggingOut')
      if (status === 'logging_in') await dispatch('onLoggingIn')
      if (status === 'logged_out') await dispatch('onLoggedOut')
      if (status === 'loggedIn') await dispatch('onLoggedIn')

      commit('setLoaded', true)
    },
    async onLoggingIn({ dispatch, state }) {
      window.localStorage.setItem('loggedIn', 'true')
      await dispatch('readWishlist')
      if (!state.wishlist) {
        await dispatch('createWishlist')
      }
    },
    async onLoggingOut({ commit }) {
      window.localStorage.setItem('loggedIn', 'false')
      window.localStorage.removeItem('wishlist_identifier')
      commit('setIdentifier', false)
      commit('setWishlist', false)
      commit('setItems', [])
    },
    async onLoggedOut() {},
    async onLoggedIn({ dispatch, state }) {
      await dispatch('readWishlist')
      if (!state.wishlist) {
        await dispatch('createWishlist')
      }
    },
    async getIdentifier({ state }) {
      let identifier = state.identifier
      if (!identifier) {
        identifier = window.localStorage.getItem('wishlist_identifier')
      }
      return identifier
    },
    async fetchItems({ state, commit }) {
      if (!state.wishlist) {
        commit('setItems', [])
        return
      }

      const items = state.wishlist.items
      const formattedItems = (
        await Promise.all(
          items.map(async (wishlistItem: WishlistItem) => {
            try {
              const shopifyProduct = await ProductApi.getByHandle({
                handle: wishlistItem.product_handle,
              })
              const variant = shopifyProduct.variants.find(
                (variant: ShopifyVariant) => variant.id === wishlistItem.variant_id
              )
              const available =
                variant.inventory_policy === 'continue' ||
                (variant.inventory_policy === 'deny' && variant.inventory_quantity > 0)

              return {
                wishlist_item: wishlistItem,
                shopify_product: shopifyProduct,
                shopify_variant: {
                  available,
                  ...variant,
                },
              }
            } catch (e) {
              return false
            }
          })
        )
      ).filter((v): v is FormattedWishlistItem => !!v)

      commit('setItems', formattedItems)
    },
    async createWishlist({ dispatch, state, commit }, items: WishlistItem[] = []) {
      const { identifier } = state
      const wishlist = await WishlistApi.createWishlist(identifier, items)
      commit('setWishlist', wishlist)
      await dispatch('fetchItems')
    },
    async readWishlist({ dispatch, state, commit }) {
      const { identifier } = state
      const wishlist = await WishlistApi.getWishlist(identifier)
      commit('setWishlist', wishlist)
      await dispatch('fetchItems')
    },
    async updateWishlist({ dispatch, state, commit }, data: any) {
      const { identifier } = state
      const wishlist = await WishlistApi.updateWishlist(identifier, data)
      commit('setWishlist', wishlist)
      await dispatch('fetchItems')
    },
    async deleteWishlist({ state }) {
      const { identifier } = state
      await WishlistApi.deleteWishlist(identifier)
    },
    async shareWishlist({ state }, data: any) {
      const { identifier } = state
      return await WishlistApi.shareWishlist(identifier, data)
    },
    async updateItems({ dispatch, state, commit }, newItems: WishlistItem[]) {
      const { identifier } = state
      const wishlist = await WishlistApi.updateWishlistItems(identifier, newItems)
      commit('setWishlist', wishlist)
      await dispatch('fetchItems')
    },
    async addItem({ dispatch, state }, item: FormattedWishlistItem) {
      await dispatch('readWishlist')
      const newItems = [item, ...state.items].map((v) => v.wishlist_item)
      dispatch('updateItems', newItems)
    },
    async removeItem({ dispatch, state }, variantId: number) {
      await dispatch('readWishlist')
      const newItems = [...state.items]
        .map((v) => v.wishlist_item)
        .filter((v) => v.variant_id !== variantId)
      dispatch('updateItems', newItems)
    },
    async updateItem({ dispatch, state }, item: FormattedWishlistItem) {
      await dispatch('readWishlist')
      const newItems = [
        ...state.items.filter((v) => v.shopify_variant.id !== item.shopify_variant.id),
        item,
      ].map((v) => v.wishlist_item)
      dispatch('updateItems', newItems)
    },
  },
  mutations: {
    set(state: WishlistState, wishlist: any) {
      state.data = wishlist
    },
    setIdentifier(state: WishlistState, identifier: string | false) {
      state.identifier = identifier
    },
    setUserStatus(state: WishlistState, status: string) {
      state.userStatus = status
    },
    setWishlist(state: WishlistState, wishlist: any) {
      state.wishlist = wishlist
    },
    setItems(state: WishlistState, items: FormattedWishlistItem[]) {
      state.items = items
    },
    setLoaded(state: WishlistState, loaded: boolean) {
      state.loaded = loaded
    },
  },
  getters: {},
}
