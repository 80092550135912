import { provide, inject, Ref, ref, onMounted, onBeforeUnmount, watch } from 'vue'

export type IntersectionContextType = {
  isOnscreen: Ref<boolean>
}

export const INTERSECTION_INJECTION_KEY = Symbol('INTERSECTION')

export const useIntersectionContext = (targetRef?: Ref<HTMLElement | null>) => {
  const isOnscreen = ref(false)
  let observer: IntersectionObserver | null = null
  let targetElement: Element | null = null

  const createObserver = (element: Element) => {
    // Cleanup previous observer if it exists
    if (observer) {
      observer.disconnect()
    }

    const options = {
      threshold: [0.5], // Trigger at 50% visibility
      rootMargin: '0px',
    }

    observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      isOnscreen.value = entry.intersectionRatio >= 0.5
    }, options)

    targetElement = element
    observer.observe(element)
  }

  // Watch for changes in the target ref
  if (targetRef) {
    watch(
      targetRef,
      (newElement) => {
        if (newElement) {
          createObserver(newElement)
        }
      },
      { immediate: true }
    )
  }

  // Fallback to id-based targeting if no ref is provided
  onMounted(() => {
    if (!targetRef) {
      const element = document.getElementById('intersection-target')
      if (element) {
        createObserver(element)
      }
    }
  })

  onBeforeUnmount(() => {
    if (observer) {
      observer.disconnect()
    }
  })

  const values = {
    isOnscreen,
  }

  provide<IntersectionContextType>(INTERSECTION_INJECTION_KEY, values)
  return values
}

export const useIntersectionInject = () => {
  const context = inject<IntersectionContextType>(INTERSECTION_INJECTION_KEY)
  if (!context) throw new Error('IntersectionContext not provided')
  return context
}
