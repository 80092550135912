<template>
  <main>
    <OverlayGallery :product="product" />

    <section class="main">
      <ProductSidebar />

      <section class="product">
        <section class="introduction">
          <ProductHeading />

          <section class="gallery">
            <ProductSlideshow />
          </section>
        </section>

        <section class="content">
          <ProductSelect />
          <ProductBuy />
          <ProductTabs />
        </section>
      </section>
    </section>

    <ProductJournal />
    <ProductMaker />
    <ProductRelated />
  </main>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'
import ProductSlideshow from './ProductSlideshow.vue'
import { ProductContextProps, useProductContext } from '../../context/useProductContext'
import { LiquidProduct } from '../../types/liquid'
import ProductSidebar from './ProductSidebar.vue'
import ProductHeading from './ProductHeading.vue'
import ProductSelect from './ProductSelect.vue'
import ProductBuy from './ProductBuy.vue'
import ProductTabs from './ProductTabs.vue'
import ProductJournal from './ProductJournal.vue'
import ProductMaker from './ProductMaker.vue'
import ProductRelated from './ProductRelated.vue'
import OverlayGallery from '../overlays/OverlayGallery.vue'

const props = defineProps<{
  product: LiquidProduct
  breadcrumbs: ProductContextProps['breadcrumbs']
}>()

useProductContext(props)
</script>
