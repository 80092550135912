export const updateItemInArray = (arr: any, replacement: any) => {
  return arr.map((item: any) => {
    if (item.name === replacement.name) {
      return replacement
    }

    return item
  })
}
export function replaceItemAtIndex<T>(array: T[], index: number, value: T): T[] {
  // Create a copy of the array
  const newArray = array.slice()

  // Replace the element at the given index
  newArray[index] = value

  // Return the new array
  return newArray
}
