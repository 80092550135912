import { Ref, computed, provide, inject, ref, onMounted } from 'vue'
import {
  LiquidCollection,
  LiquidProduct,
  LiquidProductOption,
  LiquidProductSimple,
  LiquidVariant,
} from '../liquid'
import { replaceItemAtIndex } from '../helpers'
import { fetchCollectionByHandle, fetchCollectionProductsByHandle } from '../ajax'

export type ProductContextProps = {
  product: LiquidProduct | LiquidProductSimple
  breadcrumbs: ({ url: string; title: string } | null)[]
}

export type ProductContextType = ProductContextProps & {
  variants: LiquidVariant[]

  productUrl: string
  productMinPrice: number
  productMaxPrice: number
  isProductAvailable: boolean
  hasOptions: boolean

  isVariantAvailable: Ref<boolean>
  isVariantOnSale: Ref<boolean>
  optionsWithValues: LiquidProduct['options_with_values']

  selectedVariant: Ref<LiquidVariant>
  selectedOptions: Ref<LiquidVariant['options']>
  selectedQuantity: Ref<number>

  updateVariant: any
  updateOptions: any

  makerCollection?: LiquidCollection
  makerProducts?: LiquidProductSimple[]
  relatedCollection?: LiquidCollection
  relatedProducts?: LiquidProductSimple[]

  breadcrumbs: { url: string; title: string }[]
}

export const PRODUCT_INJECTION_KEY = Symbol('PRODUCT')

export const useProductContext = ({ product, breadcrumbs }: ProductContextProps) => {
  // Product info
  const isProductAvailable: boolean = product.available
  const productUrl: string = product.url
  const productMinPrice = product.price_min / 100
  const productMaxPrice = product.price_max / 100
  const variants: LiquidVariant[] = product.variants
  const hasOptions = !product.has_only_default_variant
  const optionsWithValues: LiquidProductOption[] = product.options_with_values
  const isVariantAvailable = computed(() => selectedVariant.value.available)
  const isVariantOnSale = computed(
    () => selectedVariant.value.compare_at_price > selectedVariant.value.price
  )

  // Variant selection
  const selectedQuantity = ref(1)
  const selectedVariant: Ref<LiquidVariant> = ref(product.variants[0])
  const selectedOptions: Ref<LiquidVariant['options']> = ref(selectedVariant.value.options)

  const updateVariant = (_variant: LiquidVariant) => (selectedVariant.value = _variant)

  const updateOptions = (optionGroup: string, option: string) => {
    // Find variant that matches new options
    const newVariant = variants.find((_variant) => {
      const currentOptions = selectedVariant.value.options

      const position = optionsWithValues.find((option) => option.name == optionGroup)?.position || -1

      const index = position - 1
      const newOptions = replaceItemAtIndex(currentOptions, index, option)

      return JSON.stringify(_variant.options) == JSON.stringify(newOptions)
    })

    if (newVariant) {
      selectedOptions.value = newVariant.options
      selectedVariant.value = newVariant
    }
  }

  const makerCollection = ref<LiquidCollection | undefined>(undefined)
  const makerProducts = ref<LiquidProductSimple[] | undefined>(undefined)
  const relatedCollection = ref<LiquidCollection | undefined>(undefined)
  const relatedProducts = ref<LiquidProductSimple[] | undefined>(undefined)

  onMounted(async () => {
    const makerHandle = (product.tags as string[])
      .find((tag) => tag.startsWith('maker-'))
      ?.replace('maker-', '')

    if (makerHandle) {
      makerCollection.value = await fetchCollectionByHandle(makerHandle)
      makerProducts.value = await fetchCollectionProductsByHandle(makerHandle, { limit: 6 })
    }

    const relatedHandle = (product.tags as string[])
      .find((tag) => tag.startsWith('related-'))
      ?.replace('related-', '')

    if (relatedHandle) {
      relatedCollection.value = await fetchCollectionByHandle(relatedHandle)
      relatedProducts.value = await fetchCollectionProductsByHandle(relatedHandle, { limit: 6 })
    }
  })

  const values = {
    product,
    isProductAvailable,

    variants,
    isVariantAvailable,
    isVariantOnSale,

    productMaxPrice,
    productMinPrice,
    productUrl,

    hasOptions,
    optionsWithValues,

    selectedQuantity,
    selectedOptions,
    selectedVariant,

    updateVariant,
    updateOptions,

    breadcrumbs: breadcrumbs.filter(Boolean),

    makerCollection,
    makerProducts,

    relatedCollection,
    relatedProducts,
  }

  provide<ProductContextType>(PRODUCT_INJECTION_KEY, values)

  return values
}

export const useProductInject = () => {
  const productContext = inject<ProductContextType>(PRODUCT_INJECTION_KEY)
  if (!productContext) throw new Error()
  return productContext
}
