<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    :viewBox="viewBox" :style="svgStyle" xml:space="preserve">
    <path class="icon-search-path0" :fill="color" d="M9.6,8.9L7.2,6.6c0.7-0.9,1-1.9,0.9-2.9C8,2.6,7.5,1.6,6.6,0.9c-0.8-0.7-1.9-1-3-0.9c-1,0.1-2,0.6-2.7,1.4
    c-1.4,1.7-1.2,4.3,0.5,5.8c0.7,0.6,1.7,1,2.6,1c0,0,0,0,0.1,0c0.9,0,1.8-0.3,2.5-0.9l2.3,2.3C9,9.7,9.1,9.7,9.2,9.7l0,0
    c0.1,0,0.2,0,0.3-0.1C9.8,9.4,9.8,9.1,9.6,8.9z M4,7.3C2.2,7.3,0.7,5.8,0.7,4S2.2,0.7,4,0.7S7.3,2.2,7.3,4S5.8,7.3,4,7.3z"/>
  </svg>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

interface Props {
  width?: string;
  height?: string;
  color?: string;
}

const props = withDefaults(defineProps<Props>(), {
  width: '9.8px',
  height: '9.8px',
  color: '#808080'
});

const viewBox = "0 0 9.8 9.8";

const svgStyle = computed(() => ({
  width: props.width,
  height: props.height,
  'enable-background': 'new 0 0 9.8 9.8'
}));
</script>
