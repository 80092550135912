import { createStore, Store } from 'vuex'
import wishlists, { WishlistState } from './wishlists'
import makers, { MakersState } from './makers'
import products, { ProductsState } from './products'
import login, { LoginState } from './login'
import article, { ArticleState } from './article'
import screen, { ScreenState } from './screen'

export interface RootState {
  wishlists: WishlistState
  makers: MakersState
  products: ProductsState
  login: LoginState
  article: ArticleState
  screen: ScreenState
}

const store: Store<RootState> = createStore({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    wishlists,
    makers,
    products,
    login,
    article,
    screen,
  },
})

export default store
