<template>
  <section id="maker" class="maker" v-if="makerCollection && makerProducts">
    <TransitionBackgroundInvert>
      <div class="inner">
        <div class="heading">
          <h2 class="title">
            Maker:<br />
            {{ makerCollection.title }}
            <span class="count">({{ makerCollection.products_count }})</span>
          </h2>
          <div class="link"><a :href="makerCollection.url">Shop All</a></div>
        </div>
        <div class="collection">
          <div class="blocks blocks-maker">
            <div class="block" v-for="product in makerProducts.slice(0, 3)" :key="product.handle">
              <a class="link" :href="`/products/${product.handle}`"></a>

              <div class="image">
                <ShopifyImage
                  :src="product.featured_image.src"
                  :alt="product.featured_image.alt"
                  :ratio="`${product.featured_image.width}x${product.featured_image.height}`"
                />
              </div>
              <div class="caption">{{ product.title }}</div>
            </div>
            <div class="description">
              <div class="content">
                <ShopifyContent :content="makerCollection.description" />
              </div>
              <div class="link">
                <a :href="`/collections/${makerCollection.handle}`"
                  >Shop all {{ makerCollection.title }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </TransitionBackgroundInvert>
  </section>
</template>
<script setup lang="ts">
import { useProductInject } from '../../context/useProductContext'
import ShopifyImage from '../shopify/ShopifyImage.vue'
import ShopifyContent from '../shopify/ShopifyContent'
import TransitionBackgroundInvert from '../transition/TransitionBackgroundInvert.vue'

const { makerCollection, makerProducts } = useProductInject()
</script>
