import sanitizeHtml from 'sanitize-html'

const shareHtml = '<div is="share" :url="`https://everyday-needs.com/${shareUrl}`"></div>'

export function sanitizeContent(html: string): string {
  const sanitisedHTML = sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
    allowedClasses: {
      '*': ['row*', 'text', 'image'],
    },
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      div: ['style'],
      p: ['style'],
    },
    exclusiveFilter: (frame) => {
      return (
        ['div', 'h2', 'p', 'span', 'i'].includes(frame.tag) &&
        !frame.text.trim() &&
        frame.mediaChildren.length == 0
      )
    },
    selfClosing: ['img', 'br'],
    parseStyleAttributes: false,
  })

  const finalHtml = sanitisedHTML
    .replaceAll('<p>[share]</p>', shareHtml)
    .replaceAll('[share]', shareHtml)
    .replaceAll('<p>&nbsp;</p>', '')
    .replaceAll('<p><br /> </div></p>', '</div>')
    .replaceAll('<p><br /></div></p>', '</div>')

  return finalHtml
}
