import { provide, inject } from 'vue'
import { updateCart as _updateCart } from '../ajax'
import { getCurrencySymbol } from '../services/currency'
import { SHOPIFY_DEFAULT_CURRENCIES, SHOPIFY_URL } from '../helpers'

export type CurrencyContextType = {
  currencies: string[]
  currencyCode: string
  currencySymbol: string
  setCurrency: (code: string) => void
}

export const CURRENCY_INJECTION_KEY = Symbol('CURRENCY')

export const useCurrencyContext = (currencyCode: string) => {
  const currencySymbol = getCurrencySymbol(currencyCode)

  const setCurrency = async (currency: string) => {
    await _updateCart({ currency })
    window.location.reload()
  }

  const values = {
    currencies: SHOPIFY_DEFAULT_CURRENCIES,
    currencyCode,
    currencySymbol,
    setCurrency,
  }

  provide<CurrencyContextType>(CURRENCY_INJECTION_KEY, values)
  return values
}

export const useCurrencyInject = () => {
  const context = inject<CurrencyContextType>(CURRENCY_INJECTION_KEY)
  if (!context) throw new Error()
  return context
}
