<template>
  <div class="shopify-money">
    <div class="shopify-money__symbol">{{ currencySymbol }}</div>
    <div class="shopify-money__amount">{{ number }}</div>
    <div class="shopify-money__code" v-if="codeDisplay">
      {{ codeDisplay }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useCurrencyInject } from '../../context/useCurrencyContext'
import { formatNumber } from '../../helpers/price'
import { SHOPIFY_DEFAULT_CURRENCY } from '../../helpers'

const props = defineProps<{
  showDefaultCurrencyCode: boolean
  showCurrencyCode: boolean
  hideDoubleZeros: boolean
  amount: number
}>()

const { currencyCode, currencySymbol } = useCurrencyInject()

const codeDisplay = computed(() => {
  if (!props.showDefaultCurrencyCode && currencyCode == SHOPIFY_DEFAULT_CURRENCY) {
    return ''
  }

  if (!props.showCurrencyCode) return ''

  return currencyCode
})

const number = computed(() => {
  const formatted = formatNumber({
    amount: props.amount,
    code: currencyCode,
    showZeroCents: !props.hideDoubleZeros,
  })

  return formatted.number.indexOf('.') > 0 ? parseFloat(formatted.number).toFixed(2) : formatted.number
})
</script>

<style lang="scss">
.shopify-money {
  display: inline-flex;

  &__code {
    margin-left: 0.2em;
  }

  &__amount {
    // margin-left: 0.1em;
  }
}
</style>
