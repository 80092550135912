<template>
  <div class="product-slideshow-pager">
    <div class="product-slideshow-pager__inner">
      <div class="product-slideshow-pager__page">{{ index + 1 }} / {{ total }}</div>
      <button @click="() => setOverlay('PRODUCT')" class="product-slideshow-pager__overlay">+</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useSlideshowInject } from '../../context/useSlideshowContext'
import { useOverlayInject } from '../../context/useOverlayContext'

const { emblaApi, index, total } = useSlideshowInject()
const { setOverlay } = useOverlayInject()
</script>

<style lang="scss">
@import '~styles/base';

.product-slideshow-pager {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  font-size: 10px;

  &__inner {
    display: flex;
    padding: 8px 0;
    position: relative;
    justify-content: space-around;
  }

  &__overlay {
    position: absolute;
    right: 0;
  }
}
</style>
