<template>
  <main>
    <section class="landing">
      <div
        class="landing-inner"
        :style="`background-image: url(${shopifyImage({ src: article.image, width: 1920 })}`"
      >
        <div class="clickable" @click="scrollDown">
          <div class="controls">
            <div class="arrow">
              <SvgArrowDown />
            </div>
          </div>
          <div class="heading">
            <span class="date">{{ formatDate(article.published) }}</span>
            <h1 class="title">{{ article.title }}</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="article">
      <div class="article-body">
        <div class="text">
          <ShopifyContent :content="content" />
        </div>
      </div>

      <div class="blocks">
        <div class="block" :class="block.class" v-for="block in blocks" :key="JSON.stringify(block)">
          <div v-if="block.image" class="block-inner">
            <div class="image">
              <img :src="block.src" />
            </div>
            <span class="caption" v-if="block.alt" v-text="block.alt"> </span>
          </div>
          <div v-else-if="block.pullquote" class="block-inner">
            <blockquote v-text="block.text"></blockquote>
          </div>
          <div class="block-inner" v-else-if="block.html" v-html="block.html"></div>
        </div>
      </div>
    </section>
  </main>
</template>

<script setup lang="ts">
import { formatDate } from '../../helpers'
import { shopifyImage } from '../../helpers/shopify'
import ShopifyContent from '../shopify/ShopifyContent'
import { useArticleContext } from '../../context/useArticleContext'
import { LiquidArticle } from '../../types/liquid'
import SvgArrowDown from '../svg/SvgArrowDown.vue'

const props = defineProps<{ article: LiquidArticle }>()
const { content, blocks } = useArticleContext(props)

const scrollDown = () => {
  window.scrollTo({
    top: window.innerHeight,
    left: 0,
    behavior: 'smooth',
  })
}
</script>
