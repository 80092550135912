export const SHOPIFY_URL = 'https://everydayneeds.myshopify.com'

export const SHOPIFY_API_ENDPOINT = 'https://everydayneeds.myshopify.com/api/2024-01/graphql.json'
export const SHOPIFY_ACCESS_TOKEN = ''

export const SHOPIFY_DEFAULT_CURRENCIES = ['NZD', 'USD', 'GBP', 'EUR', 'CAD']
export const SHOPIFY_DEFAULT_COUNTRY_CODE = 'NZ'
export const SHOPIFY_DEFAULT_CURRENCY = window?.Shopify?.currency?.active || 'NZD'
export const SHOPIFY_DEFAULT_SYMBOL = '$'

export const SHOPIFY_COOKIE_CURRENCY = 'CURRENCY_COOKIE'

export const KLAVIYO_LIST_ID = 'WYiXu3'
