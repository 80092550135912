import { h } from 'vue'

import { sanitizeContent } from '../../helpers/content'

export default {
  name: 'shopifyContent',
  props: {
    content: {
      required: true,
      type: String,
    },
  },
  render() {
    return h({
      template: sanitizeContent(this.content),
      components: {
        // Reveal,
        // Share,
      },
      data() {
        return {
          // shareUrl: this.shareUrl,
        }
      },
    })
  },
}
