<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" :viewBox="viewBox" :width="width" :height="height">
    <path class="st0" id="polygon3712" transform="rotate(-90 13.85 13.85)" :fill="color" d="M27.7 10V7.6H3.9l4.5-6.2-2-1.4L0 8.8l6.4 8.8 2-1.4L3.9 10z"/>
  </svg>
</template>

<script lang="ts" setup>
interface Props {
  width?: string;
  height?: string;
  color?: string;
}

withDefaults(defineProps<Props>(), {
  width: '17.6',
  height: '27.7',
  color: '#ffffff'
});

const viewBox = "0 0 17.6 27.7";
</script>
