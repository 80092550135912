import { ActionContext } from 'vuex'
import { RootState } from './index'

export interface ScreenState {
  loaded: boolean
  scrollTopOld: number
  scrollTopCurrent: number
  scrollTopDirection: string
  resizeWidthOld: number
  resizeWidthCurrent: number
  resizeWidthDirection: string
  resizeHeightOld: number
  resizeHeightCurrent: number
  resizeHeightDirection: string
  documentHeightOld: number
  documentHeightCurrent: number
  innerHeight: number
  innerWidth: number
  pageYOffset: number
}

function getDocumentHeight(): number {
  return Math.max(
    document.documentElement['clientHeight'],
    document.body['scrollHeight'],
    document.documentElement['scrollHeight'],
    document.body['offsetHeight'],
    document.documentElement['offsetHeight']
  )
}

function setupRequestAnimationFrame(): void {
  let lastTime = 0
  const vendors = ['webkit', 'moz']
  for (let x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
    window.requestAnimationFrame = window[
      vendors[x] + 'RequestAnimationFrame'
    ] as typeof window.requestAnimationFrame
    window.cancelAnimationFrame =
      (window[vendors[x] + 'CancelAnimationFrame'] as typeof window.cancelAnimationFrame) ||
      (window[vendors[x] + 'CancelRequestAnimationFrame'] as typeof window.cancelAnimationFrame)
  }

  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = function (callback: FrameRequestCallback): number {
      const currTime = new Date().getTime()
      const timeToCall = Math.max(0, 16 - (currTime - lastTime))
      const id = window.setTimeout(() => callback(currTime + timeToCall), timeToCall)
      lastTime = currTime + timeToCall
      return id
    }
  }

  if (!window.cancelAnimationFrame) {
    window.cancelAnimationFrame = function (id: number): void {
      clearTimeout(id)
    }
  }
}

export default {
  namespaced: true,
  state: (): ScreenState => ({
    loaded: false,
    scrollTopOld: -1,
    scrollTopCurrent: 0,
    scrollTopDirection: 'none',
    resizeWidthOld: -1,
    resizeWidthCurrent: 0,
    resizeWidthDirection: 'none',
    resizeHeightOld: -1,
    resizeHeightCurrent: 0,
    resizeHeightDirection: 'none',
    documentHeightOld: -1,
    documentHeightCurrent: 0,
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    pageYOffset: window.pageYOffset,
  }),
  mutations: {
    setLoaded(state: ScreenState, loaded: boolean): void {
      state.loaded = loaded
    },
    updateScroll(state: ScreenState, { top, direction }: { top: number; direction: string }): void {
      state.scrollTopOld = state.scrollTopCurrent
      state.scrollTopCurrent = top
      state.scrollTopDirection = direction
      state.pageYOffset = window.pageYOffset
    },
    updateResize(
      state: ScreenState,
      {
        width,
        height,
        widthDirection,
        heightDirection,
      }: { width: number; height: number; widthDirection: string; heightDirection: string }
    ): void {
      state.resizeWidthOld = state.resizeWidthCurrent
      state.resizeHeightOld = state.resizeHeightCurrent
      state.resizeWidthCurrent = width
      state.resizeHeightCurrent = height
      state.resizeWidthDirection = widthDirection
      state.resizeHeightDirection = heightDirection
      state.innerHeight = window.innerHeight
      state.innerWidth = window.innerWidth
    },
    updateDocumentHeight(state: ScreenState, height: number): void {
      state.documentHeightOld = state.documentHeightCurrent
      state.documentHeightCurrent = height
    },
    updateBrowserDetails(state: ScreenState): void {
      state.innerHeight = window.innerHeight
      state.innerWidth = window.innerWidth
      state.pageYOffset = window.pageYOffset
    },
  },
  actions: {
    initialize({ commit, dispatch }: ActionContext<ScreenState, RootState>): void {
      commit('setLoaded', false)
      dispatch('reset')
      dispatch('startLoop')
      setupRequestAnimationFrame()

      window.addEventListener('load', () => {
        commit('setLoaded', true)
        dispatch('reset')
      })

      window.addEventListener('resize', () => {
        commit('updateBrowserDetails')
      })

      window.addEventListener('scroll', () => {
        commit('updateBrowserDetails')
      })
    },
    reset({ commit }: ActionContext<ScreenState, RootState>): void {
      commit('updateScroll', { top: 0, direction: 'none' })
      commit('updateResize', {
        width: 0,
        height: 0,
        widthDirection: 'none',
        heightDirection: 'none',
      })
      commit('updateDocumentHeight', 0)
    },
    startLoop({ dispatch }: ActionContext<ScreenState, RootState>): void {
      const loop = (): void => {
        dispatch('checkScroll')
        dispatch('checkResize')
        dispatch('checkDocumentHeight')
        requestAnimationFrame(loop)
      }
      loop()
    },
    checkScroll({ state, commit, dispatch }: ActionContext<ScreenState, RootState>): void {
      const newTop = window.pageYOffset
      if (state.scrollTopCurrent !== newTop) {
        const direction = newTop > state.scrollTopCurrent ? 'down' : 'up'
        commit('updateScroll', { top: newTop, direction })
      }
    },
    checkResize({ state, commit, dispatch }: ActionContext<ScreenState, RootState>): void {
      const newWidth = window.innerWidth
      const newHeight = window.innerHeight
      if (state.resizeWidthCurrent !== newWidth || state.resizeHeightCurrent !== newHeight) {
        const widthDirection = newWidth > state.resizeWidthCurrent ? 'more' : 'less'
        const heightDirection = newHeight > state.resizeHeightCurrent ? 'more' : 'less'
        commit('updateResize', {
          width: newWidth,
          height: newHeight,
          widthDirection,
          heightDirection,
        })
      }
    },
    checkDocumentHeight({ state, commit, dispatch }: ActionContext<ScreenState, RootState>): void {
      const newHeight = getDocumentHeight()
      if (state.documentHeightCurrent !== newHeight) {
        commit('updateDocumentHeight', newHeight)
      }
    },
  },
  getters: {
    scrollTop: (state: ScreenState): number => state.scrollTopCurrent,
    scrollDirection: (state: ScreenState): string => state.scrollTopDirection,
    windowWidth: (state: ScreenState): number => state.resizeWidthCurrent,
    windowHeight: (state: ScreenState): number => state.resizeHeightCurrent,
    documentHeight: (state: ScreenState): number => state.documentHeightCurrent,
  },
}
