<template>
  <main class="not-found">
    <section class="details">
      <div class="heading">
        <h1>{{ settings['404_heading'] }}</h1>
      </div>
      <div class="content" v-html="settings['404_text']"></div>
    </section>
  </main>
</template>

<script lang="ts" setup>
import { useAppInject } from '../../context/useAppContext'

const { settings } = useAppInject()
</script>
