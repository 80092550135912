<template>
  <main>
    <section class="landing">
      <div class="logo">
        <SvgLogo />
      </div>
      <div class="scroll" @click="scrollDown"></div>
      <button class="arrow" @click="scrollDown">
        <SvgArrowDownWhite />
      </button>
      <div class="caption-top">
        <CommonSetting name="home_landing_caption_top" />
      </div>
      <div class="caption-bottom">
        <CommonSetting name="home_landing_caption_bottom" />
      </div>
      <div
        v-if="settings.home_landing_landscape"
        class="image"
        :style="`background-image: url('${imageUrl(settings.home_landing_image_left, '1920x')}')`"
      ></div>
      <div
        v-if="!settings.home_landing_landscape"
        class="image-left"
        :style="`background-image: url('${imageUrl(settings.home_landing_image_left, '960')}')`"
      ></div>
      <div
        v-if="!settings.home_landing_landscape"
        class="image-right"
        :style="`background-image: url('${imageUrl(settings.home_landing_image_right, '960')}')`"
      ></div>
    </section>

    <section class="arrivals">
      <div class="blocks blocks-home-arrivals">
        <div class="block">
          <a :href="settings.home_arrivals_block1_url" class="link"></a>
          <div class="image">
            <img :src="imageUrl(settings.home_arrivals_block1_image, '600x')" />
          </div>
          <div class="caption">
            <h2>
              <CommonSetting name="home_arrivals_block1_title" />
            </h2>
            <p>
              <a :href="settings.home_arrivals_block1_url">
                <CommonSetting name="home_arrivals_block1_link"
              /></a>
            </p>
          </div>
        </div>
        <div class="block">
          <a :href="settings.home_arrivals_block2_url" class="link"></a>
          <div class="image">
            <img :src="imageUrl(settings.home_arrivals_block2_image, '800x')" />
          </div>
          <div class="caption">
            <CommonSetting name="home_arrivals_block2_title" />
          </div>
        </div>
      </div>
    </section>

    <section class="everyday">
      <div class="heading">
        <div class="title">
          <h2>
            <CommonSetting name="home_everyday_title" />
          </h2>
        </div>
        <div class="link">
          <a :href="settings.home_everyday_url">
            <CommonSetting name="home_everyday_link" />
          </a>
        </div>
        <div class="description">
          <CommonSetting name="home_everyday_desc" />
        </div>
      </div>
      <div class="blocks blocks-home-everyday">
        <div class="block">
          <a :href="settings.home_everyday_block_url" class="link"></a>
          <div class="image">
            <img :src="imageUrl(settings.home_everyday_block_image, '800x')" />
          </div>
          <div class="caption">
            <div class="title">
              <CommonSetting name="home_everyday_block_title" />
            </div>
            <div class="link"><a :href="settings.home_everyday_block_url">View</a></div>
          </div>
        </div>
      </div>
    </section>

    <TransitionBackgroundInvert>
      <section class="edit">
        <div class="heading">
          <div class="title">
            <h2>
              The Edit:<br />

              <CommonSetting name="home_edit_title" />
            </h2>
          </div>
          <div class="link">
            <a :href="settings.home_edit_url">
              <CommonSetting name="home_edit_link" />
            </a>
          </div>
        </div>
        <div class="image">
          <a :href="settings.home_edit_desc_url">
            <img :src="imageUrl(settings.home_edit_image, '800x')" />
          </a>
        </div>
        <div class="blocks blocks-edit-product">
          <div v-for="block in editProducts" class="block" :key="block.href">
            <a class="link" :href="block.href"></a>
            <div class="image">
              <img :src="block.src" />
            </div>
            <div class="caption">{{ block.caption }}</div>
          </div>
        </div>
        <div class="description">
          <CommonSetting name="home_edit_desc" />
          <div class="link">
            <a :href="settings.home_edit_desc_url">
              <CommonSetting name="home_edit_desc_link" />
            </a>
          </div>
        </div>
      </section>
    </TransitionBackgroundInvert>

    <section class="visit">
      <div class="heading">
        <div class="title">
          <h2>
            Visit Us:<br />

            <CommonSetting name="home_visit_title" />
          </h2>
        </div>
        <div class="link">
          <a :href="settings.home_visit_url">
            <CommonSetting name="home_visit_link" />
          </a>
        </div>
      </div>
      <div class="blocks blocks-home-store">
        <div class="block">
          <div class="left">
            <a class="cover-link" :href="settings.home_visit_block1_url"></a>
            <div class="image">
              <img :src="imageUrl(settings.home_visit_block1_image, '800x')" />
            </div>
            <div class="caption">
              <CommonSetting name="home_visit_block1_caption" />
            </div>
          </div>
          <div class="right">
            <a class="cover-link" :href="settings.home_visit_block2_url"></a>
            <div class="image">
              <img :src="imageUrl(settings.home_visit_block2_image, '600x')" />
            </div>
            <div class="caption">
              <CommonSetting name="home_visit_block2_caption" />
            </div>
            <div class="description">
              <CommonSetting name="home_visit_block2_desc" />
            </div>
            <div class="link">
              <span>
                <CommonSetting name="home_visit_block2_link" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script lang="ts" setup>
import { useAppInject } from '../../context/useAppContext'
import CommonSetting from '../common/CommonSetting.vue'
import SvgLogo from '../svg/SvgLogo.vue'
import SvgArrowDownWhite from '../svg/SvgArrowDownWhite.vue'
import TransitionBackgroundInvert from '../transition/TransitionBackgroundInvert.vue'

const { settings } = useAppInject()

const imageUrl = (url = '', format = '600x900_crop_center') => {
  const dot_pos = (~-url.lastIndexOf('.') >>> 0) + 1
  url = [url.slice(0, dot_pos), `_${format}`, url.slice(dot_pos)].join('')

  return url
}

const editProducts = [...Array(5)]
  .map((_, i) => i + 1)
  .map((i) => ({
    href: settings[`home_edit_block${i}_url`],
    src: imageUrl(settings[`home_edit_block${i}_image`], '800x'),
    caption: settings[`home_edit_block${i}_title`],
  }))

const scrollDown = () => {
  window.scrollTo({
    top: window.innerHeight,
    left: 0,
    behavior: 'smooth',
  })
}
</script>
