<template>
  <header class="main" :class="headerClasses">
    <div class="inner">
      <nav class="logo" :style="logoStyles">
        <div class="image">
          <SvgLogo />
        </div>
      </nav>

      <div class="menu-wrap">
        <nav class="menu-main">
          <ul>
            <ShopifyLinks :links="linklists['header-main']?.links" />
          </ul>
        </nav>
        <nav class="menu-collections">
          <ShopifyLinks :links="linklists['header-collections']?.links" />
        </nav>
        <nav class="account">
          <ul>
            <li>
              <span class="a" @click.prevent="setOverlay('CART')"
                >Cart (<span>{{ itemCount }}</span
                >)</span
              >
            </li>
            <li>
              <a v-if="isLoggedIn" href="/account">Account</a>
              <a v-else href="/account/login">Login</a> / <a href="/a/wishlists">Your&nbsp;Edit</a>
            </li>
            <li
              class="currency"
              @mouseover="showCurrencies = true"
              @mouseleave="showCurrencies = false"
              :class="{ active: showCurrencies }"
            >
              <span class="a">{{ currencyCode }}</span>
              <transition name="fade">
                <ul v-if="showCurrencies">
                  <li v-for="currency in currencies" :key="currency">
                    <span @click.prevent="() => setCurrency(currency)" v-text="currency"></span>
                  </li>
                </ul>
              </transition>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="spacer"></div>
  </header>
</template>

<script lang="ts" setup>
import SvgLogo from '../svg/SvgLogo.vue'
import ShopifyLinks from '../shopify/ShopifyLinks.vue'
import { useHeaderInject } from '../../context/useHeaderContext'
import { useAppInject } from '../../context/useAppContext'
import store from '../../store'
import { useCartInject } from '../../context/useCartContext'
import { useCurrencyInject } from '../../context/useCurrencyContext'
import { ref } from 'vue'
import { useOverlayInject } from '../../context/useOverlayContext'

const showCurrencies = ref(false)

const { itemCount, cart } = useCartInject()
const { currencyCode, currencies, setCurrency } = useCurrencyInject()
const { linklists, isLoggedIn } = useAppInject()
const { logoStyles, headerClasses } = useHeaderInject()
const { setOverlay } = useOverlayInject()
</script>
