<template>
  <div class="info">
    <div class="image">
      <img :src="wishlist.image" v-if="wishlist.image" />
    </div>
    <div class="title">
      The Edit:<br />
      <div v-text="wishlist.title"></div>
    </div>
    <div class="description">
      <div v-text="wishlist.description"></div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      wishlist: Object,
    },
  };
</script>
