<template>
  <div class="select">
    <!-- product/selection/price -->
    <div class="amount">
      <h3 v-if="isVariantOnSale">On Sale</h3>
      <h3 v-else>Price</h3>
      <ShopifyPrice class="price" :amount="selectedVariant.price" />
      <ShopifyPrice class="sale" v-if="isVariantOnSale" :amount="selectedVariant.compare_at_price" />
    </div>

    <!-- product/selection/options -->
    <div class="options" v-if="product.variants.length > 1">
      <ProductSelectOption v-for="option in optionsWithValues" :option="option" :key="option.name" />
    </div>
  </div>
</template>
<script setup lang="ts">
import ShopifyPrice from '../shopify/ShopifyPrice.vue'
import { useProductInject } from '../../context/useProductContext'
import ProductSelectOption from './ProductSelectOption.vue'
const { isVariantOnSale, selectedVariant, product, optionsWithValues } = useProductInject()
</script>
