import { fetchWithCache } from '../helpers'
import { LiquidCollection, LiquidProductSimple } from '../types/liquid'

export async function fetchCollectionByHandle(handle: string) {
  return await fetchWithCache<LiquidCollection>('collections', handle, async () => {
    const response = await fetch('/collections/' + handle + '?view=json', {
      method: 'GET',
    })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    return response.json()
  })
}

export async function fetchCollectionProductsByHandle(handle: string, options: any = {}) {
  const { limit = 24, page = 1, sortBy = 'best-selling' } = options

  const cacheKey = `${handle}-${limit}-${page}-${sortBy}`

  return await fetchWithCache<LiquidProductSimple[]>('products', cacheKey, async () => {
    const queryParams = new URLSearchParams({
      view: 'products',
      sort_by: sortBy,
      page: String(page),
      limit: String(limit),
    })
    const response = await fetch(`/collections/${handle}?${queryParams}`, {
      method: 'GET',
    })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    return response.json()
  })
}
