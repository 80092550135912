<template>
  <div class="notify">
    <div class="note">Email me when this item is back in stock</div>
    <form @submit.prevent="submit" class="notify-form">
      <input v-model="email" @input="clearMessages" type="email" placeholder="Email" required />
      <button>Submit</button>
    </form>
    <div v-if="!!message" class="message">
      {{ message }}
    </div>
    <div v-if="errors" class="errors">
      <div v-for="error in formattedErrors" class="error" :key="error">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'

const shop = 'everydayneeds.myshopify.com'

export default {
  props: ['product-id', 'variant-id'],
  data() {
    return {
      email: '',
      quantity: 1,
      acceptsMarketing: false,
      utcOffset: 0,
      message: null,
      errors: null,
    }
  },
  methods: {
    submit() {
      const data = {
        shop,
        'notification[email]': this.email,
        'notification[product_no]': this.productId,
        'variant[variant_no]': this.variantId,
        'notification[quantity_required]': this.quantity,
        'notification[accepts_marketing]': this.acceptsMarketing,
        'notification[customer_utc_offset]': this.utcOffset,
      }
      const query = qs.stringify(data)
      console.log('data', data, query)
      axios
        .get(`https://app.backinstock.org/stock_notification/create.json?${query}`)
        .then((res) => {
          const data = res.data
          console.log(data)
          if (data.status === 'Error') {
            this.errors = data.errors
            return
          }
          this.message = data.message
          return
        })
        .catch((error) => this.errors.push(error.message))
    },

    clearMessages() {
      this.message = null
      this.errors = null
    },
  },
  computed: {
    formattedErrors() {
      return Object.keys(this.errors).map((key) => this.errors[key].join(', '))
    },
  },
  created() {
    const dateNow = new Date()
    this.utcOffset = dateNow.getTimezoneOffset()
  },
}
</script>
