<template>
  <div class="product-slideshow">
    <CommonSlideshow
      class="product-slideshow__container"
      :slider-config="sliderConfig"
      :thumbnail-config="thumbnailConfig"
    >
      <template #default>
        <ShopifyImage
          ratio="5x7"
          v-for="(image, mediaIndex) in productImages"
          :src="image.src"
          :key="`media_${mediaIndex}`"
        />
      </template>
      <template #after>
        <ProductSlideshowControls class="product-slideshow__controls" />
        <ProductSlideshowPager class="product-slideshow__pager" />
      </template>
    </CommonSlideshow>
  </div>
</template>

<script lang="ts" setup>
import { EmblaOptionsType } from 'embla-carousel-vue'
import { useProductInject } from '../../context/useProductContext'
import CommonSlideshow from '../common/CommonSlideshow.vue'
import ShopifyImage from '../shopify/ShopifyImage.vue'
import { useOverlayInject } from '../../context/useOverlayContext'
import ProductSlideshowControls from './ProductSlideshowControls.vue'
import ProductSlideshowPager from './ProductSlideshowPager.vue'

const { product } = useProductInject()
const { setOverlay } = useOverlayInject()

const sliderConfig = { containScroll: 'keepSnaps', align: 'start' } as EmblaOptionsType

const thumbnailConfig = {
  dragFree: true,
  align: 'start',
  axis: 'y',
} as EmblaOptionsType

const productImages = product.images.filter((image) => image.alt != 'seen-in')
</script>

<style lang="scss">
@import '~styles/base';

.product-slideshow {
  position: relative;
  margin-bottom: auto;

  &__container {
    display: grid;
    position: relative;
    margin-bottom: auto;
    grid-template-columns: columns(2) columns(7, 0);
    gap: 0 var(--gutter);

    @include breakpoint('l') {
      grid-template-columns: 1fr;
    }
  }

  &__controls {
    @media not all and (pointer: fine) {
      display: none;
    }
  }

  .common-slideshow {
    &__slider {
      grid-column: 2 / 3;

      @include breakpoint('l') {
        grid-column: 1 / -1;
      }
    }
  }

  .common-slideshow__after {
    display: contents;
  }
}
</style>
