<template>
  <ul>
    <li v-for="(link, index) in links" :key="index" :class="{ search: link.url == '#search' }">
      <template v-if="link.title === '#'">&nbsp;</template>
      <template v-else-if="link.url === '#top'">
        <span class="a" @click="scrollUp">{{ link.title }}</span>
      </template>
      <template v-else-if="link.url === '#search'">
        <form action="/search">
          <input type="hidden" name="type" value="product" />
          <input type="text" name="q" placeholder="Search" required />
          <button><SvgSearchGrey /></button>
        </form>
      </template>
      <template v-else-if="link.title === '-'">
        <span class="line"></span>
      </template>
      <template v-else-if="link.url">
        <a
          :href="link.url.replace('https://www.everyday-needs.com', '')"
          :class="{ active: link.active }"
          :target="link.url.includes('instagram') || link.url.includes('facebook') ? '_blank' : ''"
        >
          {{ link.title }}
        </a>
      </template>
    </li>
    <slot />
  </ul>
</template>

<script lang="ts" setup>
import { LiquidLink } from '../../types/liquid'
import SvgSearchGrey from '../svg/SvgSearchGrey.vue'
const props = defineProps<{ links: LiquidLink[] }>()

const scrollUp = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}
</script>
