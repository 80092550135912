<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 747.3 109.2"
    xml:space="preserve"
  >
    <g>
      <path
        d="M37.8,38.9c-0.9-1-2-1.7-3.4-2.3c-1.4-0.6-3-0.8-4.8-0.8c-2,0-3.8,0.4-5.3,1.1c-1.5,0.8-2.8,1.8-3.7,3
          c-1,1.2-1.7,2.4-2.1,3.7c-0.4,1.3-0.7,2.9-0.7,4.7h23.4C41,44.4,39.9,41.2,37.8,38.9 M54.1,55.5c0,0.4,0,0.7-0.1,1.1
          c0,0.4,0,0.8,0,1.1H17.8c-0.1,2.1,0.2,4.1,0.9,5.9c0.7,1.9,1.7,3.4,3,4.7c1,0.9,2.1,1.7,3.4,2.3c1.3,0.7,2.8,1,4.6,1
          c1.9,0,3.4-0.2,4.6-0.6c1.2-0.4,2.3-1.1,3.3-2c1.3-1.3,2.1-2.8,2.6-4.6h12.5c-0.1,1.6-0.7,3.3-1.6,5.3c-0.9,1.9-1.9,3.5-3,4.7
          c-2.3,2.7-5,4.6-8.2,5.8c-3.2,1.2-6.5,1.8-10,1.8c-3.7,0-7-0.6-9.7-1.8c-2.7-1.2-5.2-2.8-7.3-4.8c-2.7-2.6-4.8-5.8-6.2-9.5
          c-1.4-3.7-2.1-7.7-2.1-12.2c0-4.4,0.6-8.4,1.9-12.1c1.3-3.7,3.2-6.9,5.9-9.5c2-2.1,4.5-3.8,7.3-5c2.9-1.2,6.2-1.9,9.9-1.9
          c4,0,7.8,0.8,11.2,2.4c3.5,1.6,6.3,4.1,8.5,7.5c2,3,3.3,6,4,9.1c0.6,3.1,0.9,6.3,0.7,9.7V55.5z"
        class="st0"
      ></path>
      <polygon
        points="89.3,80.8 75.5,80.8 55.4,26.7 69.1,26.7 82.7,64.8 95.5,26.7 108.9,26.7 	"
        class="st0"
      ></polygon>
      <path
        d="M143.3,38.9c-0.9-1-2-1.7-3.4-2.3c-1.4-0.6-3-0.8-4.8-0.8c-2,0-3.8,0.4-5.3,1.1c-1.5,0.8-2.8,1.8-3.8,3
          c-1,1.2-1.7,2.4-2.1,3.7c-0.4,1.3-0.7,2.9-0.7,4.7h23.4C146.6,44.4,145.5,41.2,143.3,38.9 M159.7,55.5c0,0.4,0,0.7-0.1,1.1
          c0,0.4,0,0.8,0,1.1h-36.2c-0.1,2.1,0.2,4.1,0.9,5.9c0.7,1.9,1.7,3.4,3,4.7c1,0.9,2.1,1.7,3.4,2.3c1.3,0.7,2.8,1,4.6,1
          c1.9,0,3.4-0.2,4.6-0.6c1.2-0.4,2.3-1.1,3.3-2c1.3-1.3,2.1-2.8,2.6-4.6h12.5c-0.1,1.6-0.7,3.3-1.6,5.3c-0.9,1.9-1.9,3.5-3,4.7
          c-2.3,2.7-5,4.6-8.2,5.8c-3.2,1.2-6.5,1.8-10,1.8c-3.7,0-7-0.6-9.7-1.8c-2.7-1.2-5.2-2.8-7.3-4.8c-2.7-2.6-4.8-5.8-6.2-9.5
          c-1.4-3.7-2.1-7.7-2.1-12.2c0-4.4,0.6-8.4,1.9-12.1c1.3-3.7,3.2-6.9,5.9-9.5c2-2.1,4.5-3.8,7.3-5c2.9-1.2,6.2-1.9,9.9-1.9
          c4,0,7.8,0.8,11.2,2.4c3.5,1.6,6.3,4.1,8.5,7.5c2,3,3.3,6,4,9.1c0.6,3.1,0.9,6.3,0.7,9.7V55.5z"
        class="st0"
      ></path>
      <path
        d="M196.2,38.1c-4.6,0-8.1,1.5-10.4,4.4c-2.4,2.9-3.5,6.6-3.5,11v27.3H169V26.7h11.1l1.2,8
          c1.3-3.1,3.3-5.3,6.2-6.7c2.9-1.4,5.9-2.1,9.1-2.1c1.4,0,2.7,0.1,4.1,0.3v12.4C199.1,38.3,197.6,38.1,196.2,38.1"
        class="st0"
      ></path>
      <path
        d="M235.5,90.1c-0.7,1.8-1.5,3.5-2.4,5.2c-0.9,1.6-2.1,3.1-3.4,4.3c-1.3,1.3-2.9,2.2-4.6,3
          c-1.8,0.7-3.8,1.1-6.2,1.1c-2.2,0-4-0.1-5.6-0.4c-1.6-0.3-3-0.6-4.4-0.9V91.4c0.8,0.1,1.5,0.3,2.1,0.4c0.6,0.1,1.1,0.2,1.7,0.3
          c0.5,0.1,1.1,0.1,1.6,0.2c0.6,0,1.2,0,1.9,0c1.2,0,2.3-0.3,3.5-0.8c1.1-0.6,2.1-1.6,2.8-3.2l3.1-7.6l-21.3-54h14.3l14.2,39.1
          l13.5-39.1h13.2L235.5,90.1z"
        class="st0"
      ></path>
      <path
        d="M297.4,53.6c0-1.9-0.2-3.9-0.5-6c-0.3-2-0.9-3.9-1.8-5.6c-0.9-1.7-2.1-3.1-3.7-4.2c-1.6-1.1-3.7-1.6-6.2-1.6
          c-2,0-3.7,0.4-5.2,1.3c-1.4,0.8-2.6,1.8-3.6,3c-1.4,1.8-2.3,3.9-2.8,6.1c-0.5,2.3-0.7,4.6-0.7,7c0,2.4,0.2,4.7,0.7,7
          c0.5,2.3,1.4,4.4,2.8,6.2c1,1.2,2.2,2.2,3.6,3c1.4,0.8,3.1,1.3,5.2,1.3c2.6,0,4.6-0.5,6.2-1.6c1.6-1.1,2.8-2.5,3.7-4.2
          c0.9-1.7,1.5-3.6,1.8-5.7C297.2,57.6,297.4,55.6,297.4,53.6 M298.5,80.8l-0.8-5.9c-1.6,2.5-3.8,4.3-6.6,5.5
          c-2.8,1.1-5.7,1.7-8.6,1.7c-2.7,0-5.2-0.4-7.4-1.3c-2.2-0.8-4.2-1.9-5.8-3.3c-3.3-2.6-5.7-6-7.2-9.9c-1.6-4-2.3-8.6-2.3-13.7
          c0-5.3,0.8-10,2.4-14.1c1.6-4.1,4-7.4,7.3-10c1.7-1.4,3.6-2.5,5.8-3.2c2.2-0.8,4.7-1.1,7.4-1.1c3.5,0,6.4,0.5,8.8,1.5
          c2.4,1,4.2,2.2,5.4,3.6V5.6h13v75.2H298.5z"
        class="st0"
      ></path>
      <path
        d="M351.6,56.8l-13.7,1c-2,0.1-3.8,0.8-5.4,2c-1.6,1.2-2.4,2.9-2.4,5.1c0,2.1,0.8,3.7,2.3,4.9
          c1.6,1.2,3.3,1.8,5.4,1.8c4.1,0,7.4-1.1,10-3.2c2.6-2.1,3.9-5.2,3.9-9.2V56.8z M366.1,81.3c-0.8,0.2-2,0.4-3.6,0.4
          c-2.8,0-5-0.8-6.6-2.3c-1.5-1.5-2.4-3.3-2.6-5.3c-1.2,2.2-3.3,4.1-6.2,5.7c-2.9,1.6-6.3,2.4-10.2,2.4c-3.2,0-6-0.5-8.5-1.4
          c-2.5-0.9-4.5-2.2-6.1-3.7c-1.6-1.6-2.9-3.4-3.7-5.4c-0.8-2-1.2-4.2-1.2-6.4c0-2.6,0.5-4.9,1.4-6.9c0.9-2,2.2-3.7,3.9-5.2
          c1.7-1.5,3.6-2.6,5.8-3.4c2.2-0.8,4.6-1.3,7.2-1.5l15.9-1.1v-3.3c0-2.6-0.6-4.6-1.8-6.2c-1.2-1.6-3.7-2.3-7.3-2.3
          c-3.1,0-5.4,0.7-7.1,2c-1.7,1.3-2.6,3.2-2.8,5.7H320c0.2-3.1,0.9-5.7,2.1-7.9c1.2-2.2,2.8-4,4.8-5.5c2-1.4,4.4-2.5,7-3.2
          c2.7-0.7,5.5-1,8.5-1c4.6,0,8.8,0.8,12.4,2.4c3.6,1.6,6.3,4.5,7.9,8.6c0.7,1.7,1.1,3.5,1.3,5.4c0.2,1.9,0.3,3.7,0.3,5.5v20.1
          c0,1.3,0.2,2.1,0.7,2.7c0.5,0.5,1.2,0.8,2.3,0.8c0.6,0,0.9,0,1.1-0.1c0.2-0.1,0.4-0.1,0.5-0.1v9.6C367.9,80.7,366.9,81,366.1,81.3"
        class="st0"
      ></path>
      <path
        d="M398.8,90.1c-0.7,1.8-1.5,3.5-2.4,5.2c-0.9,1.6-2.1,3.1-3.4,4.3c-1.3,1.3-2.9,2.2-4.6,3
          c-1.8,0.7-3.8,1.1-6.2,1.1c-2.2,0-4-0.1-5.6-0.4c-1.6-0.3-3-0.6-4.4-0.9V91.4c0.8,0.1,1.5,0.3,2.1,0.4c0.6,0.1,1.1,0.2,1.7,0.3
          c0.5,0.1,1.1,0.1,1.6,0.2c0.6,0,1.2,0,1.9,0c1.2,0,2.3-0.3,3.5-0.8c1.1-0.6,2.1-1.6,2.8-3.2l3.1-7.6l-21.3-54h14.3l14.2,39.1
          l13.5-39.1h13.2L398.8,90.1z"
        class="st0"
      ></path>
      <g>
        <path
          d="M478.4,80.7V47.6c0-3.2-0.6-5.7-1.9-7.5c-1.2-1.8-3.6-2.7-7.1-2.7c-2.3,0-4.2,0.6-5.7,1.9
          c-1.5,1.3-2.7,2.9-3.6,4.9c-1,2.1-1.5,4.5-1.6,7.1c-0.1,2.6-0.2,5-0.2,7.3v22h-13V26.6h10.9l0.9,7.1c1.6-2.9,3.9-5,6.8-6.4
          c3-1.4,6-2,9.1-2c2.9,0,5.5,0.5,7.8,1.5c2.3,1,4.2,2.3,5.7,4c1.6,1.7,2.8,3.7,3.6,6.1c0.8,2.4,1.2,5,1.2,7.8v36.1H478.4z"
          class="st0"
        ></path>
        <path
          d="M531.7,38.8c-0.9-1-2-1.7-3.4-2.3c-1.3-0.6-3-0.8-4.8-0.8c-2,0-3.8,0.4-5.3,1.1c-1.5,0.8-2.8,1.8-3.7,3
          c-1,1.2-1.7,2.4-2.1,3.7c-0.4,1.3-0.7,2.9-0.7,4.7H535C534.9,44.3,533.8,41.2,531.7,38.8 M548,55.5c0,0.4,0,0.7-0.1,1.1
          c0,0.4,0,0.8,0,1.1h-36.2c-0.1,2.1,0.2,4.1,0.9,5.9c0.7,1.9,1.7,3.4,3,4.7c1,0.9,2.1,1.7,3.4,2.3c1.3,0.7,2.8,1,4.6,1
          c1.9,0,3.4-0.2,4.6-0.6s2.3-1.1,3.3-2c1.3-1.3,2.1-2.8,2.6-4.6h12.5c-0.1,1.6-0.7,3.3-1.6,5.3c-0.9,1.9-1.9,3.5-3,4.7
          c-2.3,2.7-5,4.6-8.2,5.8c-3.2,1.2-6.5,1.8-10,1.8c-3.7,0-7-0.6-9.7-1.8c-2.7-1.2-5.2-2.8-7.3-4.8c-2.7-2.6-4.8-5.8-6.2-9.5
          c-1.4-3.7-2.1-7.7-2.1-12.2c0-4.4,0.6-8.4,1.9-12.1c1.3-3.7,3.2-6.9,5.9-9.5c2-2.1,4.5-3.8,7.3-5c2.9-1.3,6.2-1.9,9.9-1.9
          c4,0,7.8,0.8,11.2,2.4c3.5,1.6,6.3,4.1,8.5,7.5c2,3,3.3,6,4,9.1c0.6,3.1,0.9,6.3,0.7,9.7V55.5z"
          class="st0"
        ></path>
        <path
          d="M588.6,38.8c-0.9-1-2-1.7-3.4-2.3c-1.4-0.6-3-0.8-4.8-0.8c-2,0-3.8,0.4-5.3,1.1c-1.5,0.8-2.8,1.8-3.7,3
          c-1,1.2-1.7,2.4-2.1,3.7c-0.4,1.3-0.7,2.9-0.7,4.7h23.4C591.9,44.3,590.8,41.2,588.6,38.8 M604.9,55.5c0,0.4,0,0.7-0.1,1.1
          c0,0.4,0,0.8,0,1.1h-36.2c-0.1,2.1,0.2,4.1,0.9,5.9c0.7,1.9,1.7,3.4,3,4.7c1,0.9,2.1,1.7,3.4,2.3c1.3,0.7,2.8,1,4.6,1
          c1.9,0,3.4-0.2,4.6-0.6c1.2-0.4,2.3-1.1,3.3-2c1.3-1.3,2.1-2.8,2.6-4.6h12.5c-0.1,1.6-0.7,3.3-1.6,5.3c-0.9,1.9-1.9,3.5-3,4.7
          c-2.3,2.7-5,4.6-8.2,5.8c-3.2,1.2-6.5,1.8-10,1.8c-3.7,0-7-0.6-9.7-1.8c-2.7-1.2-5.2-2.8-7.3-4.8c-2.7-2.6-4.8-5.8-6.2-9.5
          c-1.4-3.7-2.1-7.7-2.1-12.2c0-4.4,0.6-8.4,1.9-12.1c1.3-3.7,3.2-6.9,5.9-9.5c2-2.1,4.5-3.8,7.3-5c2.9-1.3,6.2-1.9,9.9-1.9
          c4,0,7.8,0.8,11.2,2.4c3.5,1.6,6.3,4.1,8.5,7.5c2,3,3.3,6,4,9.1c0.6,3.1,0.9,6.3,0.7,9.7V55.5z"
          class="st0"
        ></path>
        <path
          d="M650.5,53.6c0-1.9-0.2-3.9-0.5-6c-0.3-2-0.9-3.9-1.8-5.6c-0.9-1.7-2.1-3.1-3.7-4.2c-1.6-1.1-3.7-1.6-6.2-1.6
          c-2,0-3.7,0.4-5.2,1.3c-1.4,0.8-2.6,1.8-3.6,3c-1.4,1.8-2.3,3.9-2.8,6.1c-0.5,2.3-0.7,4.6-0.7,7c0,2.4,0.2,4.7,0.7,7
          c0.5,2.3,1.4,4.4,2.8,6.2c1,1.2,2.2,2.2,3.6,3c1.4,0.8,3.1,1.2,5.2,1.2c2.6,0,4.6-0.5,6.2-1.6c1.6-1.1,2.8-2.5,3.7-4.2
          c0.9-1.7,1.5-3.6,1.8-5.7C650.4,57.5,650.5,55.5,650.5,53.6 M651.7,80.8l-0.8-5.9c-1.6,2.5-3.8,4.3-6.6,5.5
          c-2.8,1.1-5.7,1.7-8.6,1.7c-2.7,0-5.2-0.4-7.4-1.3c-2.2-0.8-4.2-1.9-5.8-3.3c-3.3-2.6-5.7-6-7.2-9.9c-1.6-4-2.3-8.6-2.3-13.7
          c0-5.3,0.8-10,2.4-14.1c1.6-4.1,4-7.4,7.3-10c1.7-1.4,3.6-2.5,5.8-3.2c2.2-0.8,4.7-1.1,7.4-1.1c3.5,0,6.4,0.5,8.8,1.5
          c2.4,1,4.2,2.2,5.4,3.6V5.6h13v75.2H651.7z"
          class="st0"
        ></path>
        <path
          d="M717,64c0,2.6-0.4,4.9-1.3,6.9c-0.9,1.9-2,3.6-3.4,5c-2.2,2.2-4.9,3.8-8.1,4.8c-3.2,1-6.7,1.5-10.4,1.5
          c-3.5,0-6.7-0.5-9.7-1.5c-3-1-5.6-2.6-7.8-4.7c-1.6-1.5-3-3.4-4.1-5.5c-1.1-2.1-1.7-4.5-1.7-7.1h12.2c0,1.2,0.3,2.4,0.8,3.5
          c0.6,1.2,1.2,2.1,2.1,2.8c1,0.8,2.2,1.4,3.5,1.8c1.3,0.4,2.9,0.6,4.7,0.6c1.1,0,2.3-0.1,3.6-0.3c1.3-0.2,2.5-0.5,3.6-1
          c1.1-0.5,2-1.2,2.8-2.1c0.7-0.9,1.1-2.1,1.1-3.5c0-1.6-0.5-2.9-1.5-3.9c-1-1-2.3-1.6-4-1.9c-2.4-0.3-4.9-0.6-7.5-0.9
          c-2.6-0.2-5.1-0.6-7.5-1.2c-1.9-0.4-3.6-1.1-5-2c-1.5-0.9-2.8-2-3.9-3.3c-1.1-1.3-2-2.7-2.6-4.3c-0.6-1.6-0.9-3.2-0.9-4.9
          c0-2.8,0.5-5.2,1.6-7.1c1-2,2.4-3.7,4-5c2-1.7,4.4-3.1,7.2-4c2.8-0.9,5.9-1.4,9.3-1.4c3.3,0,6.4,0.5,9.4,1.6c3,1,5.5,2.6,7.5,4.8
          c1.5,1.6,2.6,3.3,3.5,5.3c0.9,1.9,1.3,3.9,1.3,5.9h-12.4c0-1-0.2-2-0.6-2.8c-0.4-0.8-0.9-1.6-1.6-2.3c-0.8-0.8-1.8-1.4-3.1-1.8
          c-1.3-0.5-2.6-0.7-4-0.7c-1,0-1.9,0-2.8,0.1s-1.8,0.3-2.8,0.7c-1.2,0.5-2.2,1.2-3,2.2c-0.8,1-1.2,2.2-1.2,3.5c0,1.9,0.7,3.3,2,4
          c1.3,0.7,2.8,1.2,4.2,1.4c1.3,0.1,2.3,0.3,3.1,0.4l2.5,0.3c0.8,0.1,1.7,0.2,2.6,0.4c0.9,0.1,2,0.3,3.3,0.5
          c4.3,0.7,7.8,2.3,10.6,4.9C715.7,56.2,717,59.7,717,64"
          class="st0"
        ></path>
        <g>
          <path
            d="M734.4,30.9h-2.1v2.6h2.1c0.9,0,1.4-0.5,1.4-1.3C735.8,31.5,735.3,30.9,734.4,30.9 M735.7,38.3l-1.5-3.3
          h-1.9v3.3h-1.6v-8.8h3.7c2.1,0,3.1,1.3,3.1,2.7c0,1.2-0.6,2.1-1.6,2.5l1.6,3.6H735.7z M726.6,34.2c0,3.9,3.2,7,7,7
          c3.9,0,7-3.1,7-7c0-3.9-3.2-7-7-7C729.8,27.2,726.7,30.4,726.6,34.2 M724.7,34.2c0-5,4-9,9-9c5,0,9,4,9,9c0,5-4,9-9,9
          C728.7,43.2,724.7,39.2,724.7,34.2"
            class="st0"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts" setup></script>

<style scoped lang="scss">
.st0 {
  fill: v-bind(color);
}
</style>
