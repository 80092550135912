<template>
  <form class="edit" @submit.prevent="save()">
    <div class="form" v-if="isEditing">
      <div class="title">
        <label>Your Edit Title:</label>
        <input type="text" v-model="title" placeholder="Title" required />
      </div>
      <div class="description">
        <label>Your Edit Description:</label>
        <input type="text" v-model="description" placeholder="Description" required />
      </div>
      <div class="name">
        <label>Your name:</label>
        <input type="text" v-model="username" placeholder="Name" required />
      </div>
      <div class="address">
        <label>Your delivery address (not public):</label>
        <textarea v-model="address" placeholder="Delivery Address"></textarea>
      </div>
      <WishlistImageUpload v-model="image" />
      <div class="save">
        <button v-text="save_button_text"></button>
      </div>
    </div>
    <div class="view" v-else>
      <div class="image" v-if="wishlist.image">
        <img :src="wishlist.image" />
      </div>
      <div class="name">
        <h3>Your Name:</h3>
        <div v-text="wishlist.username"></div>
      </div>
      <div class="title">
        <h3>Your Edit Title:</h3>
        <div v-text="wishlist.title"></div>
      </div>
      <div class="description">
        <h3>Your Edit Description:</h3>
        <div v-text="wishlist.description"></div>
      </div>
      <div class="address" v-if="!!wishlist.address && wishlist.address != ''">
        <h3>Delivery Address:</h3>
        <div v-text="wishlist.address"></div>
      </div>

      <div class="toggle">
        <span class="a" @click="showForm()">Edit your Details</span>
      </div>
    </div>
  </form>
</template>

<script>
import WishlistImageUpload from './WishlistImageUpload.vue'

export default {
  props: {
    wishlist: {},
  },
  data() {
    return {
      username: false,
      title: false,
      description: false,
      address: false,
      image: false,
      property_names: ['username', 'title', 'description', 'address', 'image'],
      save_button_text: 'Save',
      isEditing: false,
    }
  },
  mounted() {
    let wishlist = Object.assign({}, this.wishlist)

    this.property_names.forEach((name) => {
      this[name] = wishlist[name] || ''
    })

    if (!this.title) this.isEditing = true
  },
  watch: {
    wishlist() {
      this.save_button_text = 'Saved'
      setTimeout(() => {
        this.save_button_text = 'Save'
        this.isEditing = false
      }, 3000)
    },
  },
  methods: {
    async save() {
      let update_data = {}
      this.property_names.forEach((name) => {
        update_data[name] = this[name] || ''
      })

      this.$store.dispatch('wishlists/updateWishlist', update_data)
      this.save_button_text = 'Saving'
    },
    showForm() {
      this.isEditing = true
    },
  },
  components: {
    WishlistImageUpload,
  },
}
</script>
