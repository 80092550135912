import { provide, inject, Ref, ref, watch } from 'vue'
import { LiquidBlog, LiquidPagination } from '../types/liquid'

export type BlogContextProps = {
  blog: LiquidBlog
  pagination: LiquidPagination
}

export type BlogContextType = BlogContextProps

export const BLOG_INJECTION_KEY = Symbol('BLOG')

export const useBlogContext = ({ blog, pagination }: BlogContextProps) => {
  const values = {
    blog,
    pagination,
  }

  provide<BlogContextType>(BLOG_INJECTION_KEY, values)
  return values
}

export const useBlogInject = () => {
  const context = inject<BlogContextType>(BLOG_INJECTION_KEY)
  if (!context) throw new Error()
  return context
}
