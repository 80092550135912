<template>
  <div class="overlay overlay-gallery" v-if="current == 'PRODUCT'">
    <div class="controls">
      <div class="close" @click="setOverlay('')"></div>
    </div>
    <div class="inner">
      <div class="slide" v-for="image in product.images" :key="image.src">
        <ShopifyImage :src="image.src" :alt="image.alt" :ratio="`${image.width}x${image.height}`" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useOverlayInject } from '../../context/useOverlayContext'
import { LiquidProduct } from '../../types/liquid'
import ShopifyImage from '../shopify/ShopifyImage.vue'

const props = defineProps<{ product: LiquidProduct }>()
const { current, setOverlay } = useOverlayInject()
</script>
