import { provide, inject, Ref, ref, computed } from 'vue'
import { useAppInject } from './useAppContext'
import { useStore } from 'vuex'
import BezierEasing from 'bezier-easing'

export type HeaderContextType = {
  menuActive: Ref<boolean>
  hamburgerActive: Ref<boolean>
  toggleMenu: any
  hamburgerDimensions: Ref<(xy: 'x' | 'y', idx: number) => number>
  logoStyles: Ref<Record<string, string>>
  headerClasses: Ref<string[]>
}

export const HEADER_INJECTION_KEY = Symbol('HEADER')

export const useHeaderContext = ({ isTemplate }): HeaderContextType => {
  const { state } = useStore()
  const menuActive = ref(false)
  const hamburgerActive = ref(false)

  const toggleMenu = (active?: boolean) => {
    menuActive.value = typeof active !== 'undefined' ? active : !menuActive.value
  }

  const hamburgerDimensions = computed(() => (xy: 'x' | 'y', idx: number) => {
    const pos = [
      { x: 2.8, y: 0 },
      { x: 2.8, y: 138.3 },
      { x: 2.8, y: 276.9 },
    ]

    if (hamburgerActive.value) {
      pos[0].y = 0
      pos[1].y = 30
      pos[2].y = 60
    }

    if (menuActive.value) {
      pos[0].x = 20
      pos[1].x = 20
      pos[0].y = 20
      pos[1].y = 20
    }

    return pos[idx - 1][xy]
  })

  const logoStyles = computed(() => {
    const styles: Record<string, string> = {}
    if (!isTemplate('index')) return styles

    const windowHeight = state.screen.innerHeight
    const windowWidth = state.screen.innerWidth
    const scrollTop = state.screen.pageYOffset
    const startWidth = 300
    const endWidth = windowWidth < 1200 ? windowWidth * 0.13333 : windowWidth * 0.1
    const mStartTop = windowHeight * 0.1
    const mEndTop = windowHeight * 1

    const startTop = 50
    const endTop = 15

    if (scrollTop < mEndTop && !menuActive.value) {
      const easing = BezierEasing(0.455, 0.03, 0.515, 0.955)
      let multiplier = (scrollTop - mStartTop) / (mEndTop - mStartTop)

      multiplier = easing(multiplier)

      const widthSize = startWidth + multiplier * (endWidth - startWidth)
      const topSize = startTop + multiplier * (endTop - startTop)

      styles['width'] = `${widthSize}px`
      styles['top'] = `${topSize}px`
    }

    return styles
  })

  const headerClasses = computed(() => {
    const windowHeight = state.screen.resizeHeightCurrent
    const scrollTop = state.screen.scrollTopCurrent

    const classes: string[] = []

    if (isTemplate('index')) {
      if (scrollTop < windowHeight * 1) classes.push('logo-landing')
      if (scrollTop < windowHeight) classes.push('unstick-menus')
      if (scrollTop > windowHeight + 50) {
        hamburgerActive.value = true
        classes.push('show-hamburger')
      } else {
        hamburgerActive.value = false
      }
      if (scrollTop > 200) classes.push('hide-landing-arrow')
      if (menuActive.value) classes.push('show-menu')
    } else if (isTemplate('article')) {
      if (scrollTop > windowHeight - 50) classes.push('menu-dark')
      if (scrollTop > 200) classes.push('hide-landing-arrow')
      // if (scrollTop > windowHeight + 50) {
      //   hamburgerActive.value = true
      //   classes.push('show-hamburger')
      // } else {
      //   hamburgerActive.value = false
      // }
    } else {
      if (scrollTop > 50) {
        hamburgerActive.value = true
        classes.push('show-hamburger')
      } else {
        hamburgerActive.value = false
      }
      if (menuActive.value) classes.push('show-menu')
    }

    return classes
  })

  const values = {
    menuActive,
    toggleMenu,
    hamburgerActive,
    hamburgerDimensions,
    logoStyles,
    headerClasses,
  }

  provide<HeaderContextType>(HEADER_INJECTION_KEY, values)
  return values
}

export const useHeaderInject = () => {
  const context = inject<HeaderContextType>(HEADER_INJECTION_KEY)
  if (!context) throw new Error('HeaderContext not provided')
  return context
}
