import { getCookie, setCookie } from '../helpers'

export type CurrencyCode = string
export type CurrencySymbol = string

export type CurrencyRates = {
  [key: string]: number
}

export type MoneyStateType = {
  rate: number
  currencyCode: CurrencyCode
  currencySymbol: CurrencySymbol
}

export const getCurrencySymbol = (code: CurrencyCode) => {
  if (code == 'EUR') return '€'
  if (code == 'GBP') return '₤'
  if (code == 'JPY') return '¥'
  if (code == 'KRW') return '₩'
  return '$'
}
