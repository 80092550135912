<template>
  <div class="product-journal-slideshow">
    <CommonSlideshow
      class="product-journal-slideshow__container"
      :slider-config="sliderConfig"
      :thumbnail-config="thumbnailConfig"
    >
      <template #default>
        <ShopifyImage
          ratio="6x4"
          v-for="(image, mediaIndex) in images"
          :src="image.src"
          :key="`media_${mediaIndex}`"
        />
      </template>
      <template #after>
        <ProductSlideshowControls class="product-journal-slideshow__controls" />
        <ProductSlideshowPager class="product-journal-slideshow__pager" />
      </template>
    </CommonSlideshow>
  </div>
</template>

<script lang="ts" setup>
import { EmblaOptionsType } from 'embla-carousel-vue'
import { useProductInject } from '../../context/useProductContext'
import CommonSlideshow from '../common/CommonSlideshow.vue'
import ShopifyImage from '../shopify/ShopifyImage.vue'
import ProductSlideshowControls from './ProductSlideshowControls.vue'
import ProductSlideshowPager from './ProductSlideshowPager.vue'
import { LiquidImage } from '../../types/liquid'

defineProps<{ images: LiquidImage[] }>()
const sliderConfig = { containScroll: 'keepSnaps', align: 'start' } as EmblaOptionsType

const thumbnailConfig = {
  dragFree: true,
  align: 'start',
  axis: 'y',
} as EmblaOptionsType
</script>

<style lang="scss">
@import '~styles/base';

.product-journal-slideshow {
  position: relative;
  margin-bottom: auto;

  &__container {
    display: grid;
    position: relative;
    margin-bottom: auto;
    grid-template-columns: columns(2) columns(7, 0);
    gap: 0 var(--gutter);

    @include breakpoint('l') {
      grid-template-columns: 1fr;
    }
  }

  .common-slideshow {
    &__slider {
      grid-column: 2 / 3;

      @include breakpoint('l') {
        grid-column: 1 / -1;
      }
    }
  }

  .common-slideshow__after {
    display: contents;
  }
}
</style>
