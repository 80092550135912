import { provide, inject, Ref, ref, watch, onMounted } from 'vue'
import { LiquidArticle, LiquidCollection, LiquidProductSimple } from '../types/liquid'
import { fetchCollectionByHandle, fetchCollectionProductsByHandle } from '../ajax'

// Types
export type ArticleContextProps = {
  article: LiquidArticle
}

export type Block = {
  html?: string
  image?: boolean
  src?: string
  alt?: string
  pullquote?: boolean
  text?: string
  class?: string
}

export type ArticleContextType = ArticleContextProps & {
  blocks: Block[]
  content: string

  imageClass?: string
  makerCollection?: LiquidCollection
  makerProducts?: LiquidProductSimple[]
}

// Injection key
export const ARTICLE_INJECTION_KEY = Symbol('ARTICLE')

// Provider
export const useArticleContext = ({ article }: ArticleContextProps) => {
  const imgIndex = article.content.indexOf('<img')
  const splitContent =
    imgIndex >= 0
      ? `${article.content.substring(0, imgIndex)}<split>
        ${article.content.substring(imgIndex)}`.split('<split>')
      : [article.content]

  const content = splitContent[0]
  const blocks = splitContent[1] ? formatBlocks(splitContent[1]) : []

  // Load Maker and Collection
  const makerCollection = ref<ArticleContextType['makerCollection']>(undefined)
  const makerProducts = ref<ArticleContextType['makerProducts']>(undefined)

  const imageClass = article.tags.find((tag) => tag?.startsWith('image-'))?.replace('image-', '') || ''

  onMounted(async () => {
    const makerHandle = (article.tags as string[])
      .find((tag) => tag.startsWith('collection-'))
      ?.replace('collection-', '')

    if (makerHandle) {
      try {
        makerCollection.value = await fetchCollectionByHandle(makerHandle)
        makerProducts.value = await fetchCollectionProductsByHandle(makerHandle, { limit: 6 })
      } catch (e) {
        return
      }
    }
  })

  const values = {
    article,
    blocks,
    content,
    makerCollection,
    makerProducts,
    imageClass,
  }

  provide<ArticleContextType>(ARTICLE_INJECTION_KEY, values)
  return values
}

// Consumer
export const useArticleInject = () => {
  const context = inject<ArticleContextType>(ARTICLE_INJECTION_KEY)
  if (!context) {
    throw new Error('useArticleInject must be used within an ArticleProvider')
  }
  return context
}

// Helper functions
const formatBlocks = (imageContent: string): Block[] => {
  try {
    const els = htmlToElements(imageContent).querySelectorAll('img, blockquote, iframe')
    if (!els.length) return []

    return Array.from(els).map((el): Block => {
      if (el.nodeName === 'BLOCKQUOTE') return dissectQuote(el as HTMLQuoteElement)
      if (el.nodeName === 'IMG') return dissectImg(el as HTMLImageElement)
      return {
        html: el.outerHTML,
      }
    })
  } catch (error) {
    console.error('Error formatting blocks:', error)
    return []
  }
}

const dissectImg = (imgNode: HTMLImageElement): Block => {
  return {
    image: true,
    src: imgNode.src,
    alt: splitText(imgNode.alt),
    class: `image ${splitStyle(imgNode.alt)}`.trim(),
  }
}

const dissectQuote = (quoteNode: HTMLQuoteElement): Block => {
  const quoteStr = quoteNode.textContent || ''
  return {
    pullquote: true,
    text: splitText(quoteStr),
    class: `quote ${splitStyle(quoteStr)}`.trim(),
  }
}

const splitText = (str: string): string => splitFormat(str)[0]
const splitStyle = (str: string): string => splitFormat(str)[1] || ''
const splitFormat = (str: string): string[] => (str || '').split('<format>').map((text) => text.trim())

const htmlToElements = (html: string): DocumentFragment => {
  const template = document.createElement('template')
  template.innerHTML = html.trim()
  return template.content
}
