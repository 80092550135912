<template>
  <main>
    <section v-for="section in sections" class="details" :key="JSON.stringify(section)">
      <div class="heading">
        <h1>{{ page.title }}</h1>
      </div>
      <div class="description"><ShopifyContent :content="section.description" /></div>
      <div class="shipping" v-if="section.table">
        <ShopifyContent :content="section.table" />
      </div>
    </section>
  </main>
</template>

<script lang="ts" setup>
import { LiquidPage } from '../../typ../types/liquid'
import ShopifyContent from '../shopify/ShopifyContent'

const props = defineProps<{ page: LiquidPage }>()

const pageContents = props.page.content.split('<!--split-->')

const sections = pageContents.map((pageContent) => {
  const contents = pageContent.split('<!--table-->')

  return {
    description: contents[0],
    table: contents?.[1],
  }
})
</script>
