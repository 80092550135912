import { ActionContext } from 'vuex'
import _ from 'lodash'
import { RootState } from './index'

export type ProductsState = {
  view: number
  sidebarActive: boolean
  gapCloseOdd: boolean
}

interface Product {
  title: string
  price: number
  price_varies: boolean
  compare_at_price: number | null
  tags: string[]
  vendor: string
  variants: Array<{
    featured_image: { src: string } | null
    price: number
    compare_at_price: number | null
  }>
  available: boolean
  images: string[]
  handle: string
}

interface FormattedProduct {
  title: string
  price: number
  price_varies: boolean
  original_price: number | null
  tags: string[]
  maker: string
  variants: Array<{
    featured_image: { src: string } | null
    price: number
    compare_at_price: number | null
  }>
  available: boolean
  image: string
  url: string
}

export default {
  namespaced: true,
  state: (): ProductsState => ({
    view: 2,
    sidebarActive: false,
    gapCloseOdd: true,
  }),
  mutations: {
    setView(state: ProductsState, count: number) {
      state.view = count
    },
    toggleSidebar(state: ProductsState) {
      state.sidebarActive = !state.sidebarActive
    },
  },
  actions: {
    setView({ commit }: ActionContext<ProductsState, RootState>, count: number) {
      commit('setView', count)
    },
    toggleSidebar({ commit }: ActionContext<ProductsState, RootState>) {
      commit('toggleSidebar')
    },
    infinite(context: ActionContext<ProductsState, RootState>) {
      const totalProducts = window._data.products_total
      const remainingPages = Math.ceil(totalProducts / 50) // 50 per page

      const promises = _.range(1, remainingPages + 1).map((idx) => this.getPage(idx))

      Promise.all(promises).then((d) => {
        const products = _.chain(d).sortBy('idx').map('data').flatten().value()

        window._data.products = products
      })
    },
    getPage(context: ActionContext<ProductsState, RootState>, idx: number) {
      const collectionHandle = window._data.collection_handle

      return new Promise((res, rej) => {
        window._utils.shopify.products.collection({
          handle: collectionHandle,
          data: { page: idx },
          callback: (d) => {
            res({ idx, data: d })
          },
        })
      })
    },
  },
  getters: {
    showPrice: (state: ProductsState, getters: any, rootState: RootState, rootGetters: any) => (productId: number): string => {
      const product = rootState.products.products.find(p => p.id === productId)
      if (!product) return ''

      const price = rootGetters['currency/showPrice']({
        price: product.price,
        original: product.compare_at_price || 0,
      })

      if (!product.available) return `${price} (Sold Out)`

      return price
    },
    hasSalePrice: (variant: { price: number; compare_at_price: number | null }): boolean => {
      const price = variant.price
      const original = variant.compare_at_price
      return original != null && original < price
    },
    formatProducts: (productsData: Product[]): FormattedProduct[] => {
      const formatProduct = function (p: Product, image: string): FormattedProduct {
        return {
          title: p.title,
          price: p.price,
          price_varies: p.price_varies,
          original_price: p.compare_at_price,
          tags: p.tags,
          maker: p.vendor,
          variants: p.variants,
          available: p.available,
          image: image,
          url: `/products/${p.handle}`,
        }
      }

      return _.flatten(
        productsData.map((p) => {
          const images = p.variants
            .map((v) => v.featured_image)
            .filter((img): img is { src: string } => img != null)

          if (images.length > 0) {
            return images.map((img) => formatProduct(p, img.src))
          } else {
            return [formatProduct(p, p.images[0])]
          }
        })
      )
    },
    showBlockClass: (product: Product): string => {
      const tag = product.tags.find((t) => t.startsWith('layout-'))
      return tag ? tag.replace('layout-', '') : 'portrait-small'
    },
    getResize: (product: Product): string => {
      const tag = product.tags.find((t) => t.startsWith('layout-'))
      const layout = tag ? tag.replace('layout-', '') : 'portrait-small'

      if (layout.indexOf('landscape') > -1) {
        return '800x500_crop_center'
      } else if (layout === 'portrait-large') {
        return '900x1200_crop_center'
      } else {
        return '600x800_crop_center'
      }
    },
    showSidebarClass: (state: ProductsState): { active: boolean } => ({
      active: state.sidebarActive
    }),
  },
}
