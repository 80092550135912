<template>
  <div class="collection-page">
    <!-- with sidebar -->
    <CollectionSidebar :sidebar-menu="sidebarMenu" v-if="sidebarMenu" />
    <!-- without sidebar -->
    <template v-if="!sidebarMenu">
      <!-- maker collection -->
      <section v-if="collection.description" class="heading heading--maker">
        <h1 class="heading__title">
          Maker:<br />
          {{ collection.title }}
        </h1>
        <div class="heading__description" v-html="collection.description"></div>
      </section>
      <!-- standard collection -->
      <section v-else class="heading heading--collection">
        <h1 class="heading__title">
          {{ collection.title }}
          <span class="count">({{ collection.all_products_count }})</span>
        </h1>
      </section>
    </template>

    <section class="products">
      <div class="blocks blocks-product">
        <ProductCard :product="product" v-for="product in products" :key="product.handle" />
      </div>
    </section>
    <CollectionPagination />
  </div>
</template>

<script lang="ts" setup>
import {
  LiquidCollection,
  LiquidLinkList,
  LiquidPagination,
  LiquidProductSimple,
} from '../../types/liquid'
import CollectionSidebar from './CollectionSidebar.vue'
import ProductCard from '../product/ProductCard.vue'
import CollectionPagination from './CollectionPagination.vue'
import { useCollectionContext } from '../../context/useCollectionContext'

const props = defineProps<{
  collection: LiquidCollection
  products: LiquidProductSimple[]
  pagination: LiquidPagination
  sidebarMenu: LiquidLinkList
}>()

useCollectionContext(props)
</script>
