<template>
  <div class="block">
    <!-- item link -->
    <a class="link" v-bind:href="v.product.showUrl(item.shopify_product.handle)"></a>

    <!-- item image -->
    <div class="image">
      <img v-bind:src="v.product.showImage(getImageUrl, v.products.getResize(item.shopify_product))" />
    </div>

    <!-- item details -->
    <div class="caption">
      <!-- item title -->
      <div class="title">
        <span
          v-text="v.product.showTitle(item.shopify_product.title, item.shopify_variant.title)"
        ></span>
        <span
          class="price"
          v-html="
            $store.getters['product/showPrice']({
              price: item.shopify_variant.price,
              original: item.shopify_variant.compare_at_price,
              available: item.shopify_variant.available,
            })
          "
        ></span>
      </div>

      <!-- guest item options -->
      <div class="guest" v-if="mode == 'guest'">
        <!-- add item to cart -->
        <button
          class="buy"
          @click.prevent="buy(item.shopify_variant.id, getWishlistID)"
          v-if="item.shopify_variant.available"
        >
          Add to Cart
        </button>
      </div>

      <!-- owners item options -->
      <div class="owner" v-if="mode == 'owner'">
        <!-- edit wishlist quantity -->
        <span class="quantity" v-if="mode == 'owner' && item.shopify_variant.available">
          Qty:
          <span
            class="update"
            @click="updateQuantity(item, false)"
            v-if="item.wishlist_item.quantity > 1"
            >–</span
          >
          {{ item.wishlist_item.quantity }}
          <span class="update" @click="updateQuantity(item, true)">+</span>
        </span>
        <!-- add item to cart -->
        <button
          class="buy"
          @click.prevent="buy(item.shopify_variant.id, getWishlistID)"
          v-if="item.shopify_variant.available"
        >
          Add to Cart
        </button>
        <span class="" v-if="item.shopify_variant.available"> &nbsp;/&nbsp; </span>

        <!-- remove item from cart -->
        <button class="remove" @click.prevent="remove(item)">Remove</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bus: Object,
    mode: String,
    wishlist: Object,
    item: Object,
  },
  computed: {
    getWishlistID() {
      return this.$store.state.wishlists.identifier
    },
    v() {
      return global._app.view
    },
    s() {
      return global._app.state
    },
    m() {
      return global._app.mutator
    },
    getItems() {
      const filtered_items = this.items.filter(
        (v) => !!v.shopify_variant.available && v.wishlist_item.quantity > 0
      )

      return filtered_items
    },
    getImageUrl() {
      const variant = this.item.shopify_variant
      if (variant.featured_image) return variant.featured_image

      return this.item.shopify_product.images[0].src
    },
  },
  methods: {
    buy(id, wishlist_id) {
      //Add wishlist item to the cart
      window._app.bus.$emit('cart/item/add', {
        id,
        properties: {
          wishlist_id,
        },
      })
    },
    remove(item) {
      this.$store.dispatch('wishlists/removeItem', item.shopify_variant.id)
    },
    updateQuantity(item, toggle = true) {
      const new_quantity = !!toggle ? item.wishlist_item.quantity + 1 : item.wishlist_item.quantity - 1
      item.wishlist_item.quantity = new_quantity
      this.$store.dispatch('wishlists/updateItem', item.wishlist_item)
    },
  },
}
</script>
