<template>
  <div ref="target">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'
import { useIntersectionContext } from '../../context/useIntersectionContext'
import { useAppInject } from '../../context/useAppContext'
import { useLayoutClassesInject } from '../../context/useLayoutClasses'

const target = ref()
const { isOnscreen } = useIntersectionContext(target)
const { invert } = useLayoutClassesInject()

watch(isOnscreen, (value) => {
  invert.value = value
})
</script>
