<template>
  <div class="wishlist-items">
    <div class="blocks blocks-wishlist">
      <Item
        v-for="(item, k) in items"
        :item="item"
        :key="`item_${k}`"
        :wishlist="wishlist"
        :mode="mode"
        :bus="bus"
      ></Item>
    </div>
  </div>
</template>

<script>
import Item from './WishlistItem.vue'

export default {
  props: {
    mode: String,
    items: Array,
    wishlist: Object,
    bus: Object,
  },
  components: {
    Item,
  },
}
</script>
