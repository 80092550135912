<template>
  <section class="journal" v-if="images.length > 0">
    <div class="inner">
      <div class="heading">
        <h2 class="title">
          Seen In:<br />
          The Journal
        </h2>
        <div class="link"><a href="/blogs/journal">View Journal</a></div>
      </div>
      <section class="gallery">
        <ProductJournalSlideshow :images="images" />
      </section>
    </div>
  </section>
</template>
<script setup lang="ts">
import { useProductInject } from '../../context/useProductContext'
import ProductJournalSlideshow from './ProductJournalSlideshow.vue'

const { product } = useProductInject()
const images = product.images.filter((image) => image.alt == 'seen-in')
</script>
